import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { GroupCity, CityState, removeArrayItem } from '@bluebid-sdk/core'
import { isErrorResponse } from '@bluebid-sdk/api-client'
import { BlockUI } from '@bluebid-sdk/react-components'

import { getGroupCities, assignCityToGroup, unassignCityFromGroup } from '../../../lib'
import { StateCitySearch } from '../../../components/StateCitySearch'
import { successToast, errorToast } from '../../../utils/common'

/**
 * @deprecated use GroupZipcodesView
 */
export const GroupCitiesView: React.FC = () => {
  const { groupId } = useParams()
  const initialCityState: Partial<CityState> = { state: 'MA' }
  const [isLoading, setIsLoading] = useState(true)
  const [groupCities, setGroupCities] = useState<GroupCity[]>()
  const [selectedCityState, setSelectedCityState] = useState<Partial<CityState>>(initialCityState)

  useEffect(() => {
    if (groupId) {
      setIsLoading(true)
      ;(async () => {
        const response = await getGroupCities(groupId)

        if (isErrorResponse(response)) {
          errorToast(response.errorMessage)
        } else {
          setGroupCities(response.data)
        }

        setIsLoading(false)
      })()
    }
  }, [groupId])

  const handleAssignCity = async () => {
    if (!selectedCityState?.city || !selectedCityState?.state) {
      return
    }

    setIsLoading(true)

    const response = await assignCityToGroup(groupId, selectedCityState as CityState)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      setSelectedCityState(initialCityState)
      setGroupCities([...groupCities, response.data])
      successToast('City assigned to Team')
    }

    setIsLoading(false)
  }

  const handleUnassignCity = async (cityState: CityState) => {
    setIsLoading(true)

    const response = await unassignCityFromGroup(groupId, cityState)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      const updatedGroupCities = removeArrayItem(
        groupCities,
        (groupCity) => groupCity.city === cityState.city && groupCity.state === cityState.state
      )

      setGroupCities(updatedGroupCities)
      successToast('City removed from Team')
    }

    setIsLoading(false)
  }

  return (
    <div className="group-cities-view">
      <BlockUI blocking={isLoading} />

      <Row>
        <Col md={6}>
          <Card className="mt-4 mb-5" color="light">
            <CardBody>
              <CardTitle tag="h6">Assign City to Team</CardTitle>
              <StateCitySearch
                label=""
                onSelectState={(state: string) => setSelectedCityState({ ...selectedCityState, state })}
                onSelectCity={(city: string) => setSelectedCityState({ ...selectedCityState, city })}
                initialState={initialCityState?.state}
              />
              <Row>
                <Col className="text-right">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!selectedCityState?.city || !selectedCityState?.state}
                    onClick={handleAssignCity}
                  >
                    Assign
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <br />
          <h5>Assigned cities</h5>
          <ul>
            {groupCities?.map((groupCity, index) => (
              <ListItem key={index}>
                <Row>
                  <Col md={1}>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleUnassignCity({ city: groupCity.city, state: groupCity.state })}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col>
                  <ItemTextCol md={1}>{groupCity.state}</ItemTextCol>
                  <ItemTextCol>
                    <Link to={`/admin/city/${groupCity.state}/${groupCity.city}`} className="ml-2">
                      {groupCity.city}
                    </Link>
                  </ItemTextCol>
                </Row>
              </ListItem>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  )
}

const ListItem = styled.li`
  padding: 5px;
  list-style: none;

  :nth-child(odd) {
    background: #efefef;
  }
`

const ItemTextCol = styled(Col)`
  padding: 6px;
`
