import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'

import { Property, PropertyDetail } from '@bluebid-sdk/core'
import { BlockUI } from '@bluebid-sdk/react-components'

import { AuthContext } from '../../constants/context'
import { Features } from '../../lib/features'
import { AdminSecondaryActionButton } from '../adminActions/common'
import { EditDetails } from '../EditDetails'
import { updateProperty } from '../../lib/data/bluebidData'
import { successToast, errorToast } from '../../utils/common'

export const EditFactsAction: React.FC<{ property: Property, onUpdate?: (detail: PropertyDetail) => void }> = ({ property, onUpdate }) => {
  const { bluebidUser } = React.useContext(AuthContext)
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isClaimed = property.ownerId !== undefined
  const isOwner = isClaimed && bluebidUser && property.ownerId === bluebidUser.id
  const detail = property?.detail

  if (!isOwner && !Features.AdminUse) {
    return <></>
  }

  const toggle = () => setModal(!modal)

  const handleOnUpdate = async (update: Property) => {
    setIsLoading(true)

    await updateProperty(property.propertyId, {
      detail: update,
    }).then((result) => {
      if (result.statusCode && result.statusCode >= 400) {
        console.error({ result })
        setIsLoading(false)
        errorToast(result.errorMessage)
      } else if (result) {
        setIsLoading(false)
        successToast(`Property facts updated!`)
        onUpdate?.(result.detail)
        toggle()
      }
    })
  }

  return (
    <>
      <AdminSecondaryActionButton onClick={toggle}>
        <FontAwesomeIcon icon={faPencilAlt} /> Edit facts
      </AdminSecondaryActionButton>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>Edit {property?.address}</ModalHeader>
        <ModalBody>
          <BlockUI blocking={isLoading} />
          <EditDetails detail={detail} onCancel={toggle} onUpdate={handleOnUpdate} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
