import React, { useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { ModuleRegistry } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'

import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { GridContainerStyle, GridStyle, StandardSideBar } from '../CommonGrid'
import { DateValueFormatter, LinkRenderer, ResultFieldLinkRenderer, ResultFieldRenderer } from '../grid/GridRenderers'
import { ModifiedPropertyData, modifiedPropertyDataSummary } from './adminHistoryDetails/ModifiedPropertyData'
import { ModifiedValue, modifiedValueSummary } from './adminHistoryDetails/ModifiedValue'
import { ModifiedOwner } from './adminHistoryDetails/ModifiedOwner'
import { EmailAction, emailActionSummary } from './adminHistoryDetails/EmailAction'
import { Deleted, deletedSummary } from './adminHistoryDetails/Deleted'
import { Unclaimed, unclaimedSummary } from './adminHistoryDetails/Unclaimed'
import PropertySummaryCard from '../PropertySummaryCard'
import { variableToSentenceTitleCase } from '../../lib/utils'
import { ClaimNavigation } from '../../views/admin/adminComponents/ClaimNavigation'
import { GridListHeader } from '../../views/admin/GridListHeader'

ModuleRegistry.registerModules([ClientSideRowModelModule])

const actionDetailComponentMap = {
  'sentPhotosEmail': EmailAction,
  'modifiedBluebidEstimate': ModifiedValue,
  'modifiedOwner': ModifiedOwner,
  'sentEstimateEmail': EmailAction,
  'modifiedLocation': ModifiedValue,
  'modifiedPropertyData': ModifiedPropertyData,
  'deleted': Deleted,
  'unclaimed': Unclaimed
}

// simple functions that create a text summary of changes
// without looking at the detail view
const actionSummaryMap = {
  'sentPhotosEmail': emailActionSummary,
  'modifiedBluebidEstimate': modifiedValueSummary,
  'modifiedOwner': modifiedValueSummary,
  'sentEstimateEmail': emailActionSummary,
  'modifiedLocation': modifiedValueSummary,
  'modifiedPropertyData': modifiedPropertyDataSummary,
  'deleted': deletedSummary,
  'unclaimed': unclaimedSummary
}

const actions = Object.keys(actionDetailComponentMap).sort().map(key => ({
  name: variableToSentenceTitleCase(key),
  action: key
}))

const AdminHistoryDetailRenderer = params => {
  const actionHistoryRecord = params.data
  const Component = actionDetailComponentMap[actionHistoryRecord.action]

  if (!Component) {
    return (<div>{ variableToSentenceTitleCase(actionHistoryRecord.action) }</div>)
  }

  const data = params.data[actionHistoryRecord.action]
  return (
    <div className='p-3'>
      {Component ? Component(data) : null}
    </div>
  )
}

const AdminHistoryList: React.FC = () => {
  const gridRef = useRef()
  const { propertyId } = useParams()
  const navigate = useNavigate()
  const navigateProperty = (sel) => navigate(`/admin/list-admin-history/${sel}`)
  const navigateUser = (sel) => navigate(`/admin/userprofile/${sel.value}`)

  const showAll = propertyId === undefined

  const query = !showAll ? `propertyId:${propertyId}` : undefined

  const datasource = new Datasource({
    apiCaller: api,
    index: 'adminHistory',
    sort: '-createdAt',
    query,
    adminSearch: true,
    fields: 'user'
  })

  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])

  const ActionSummaryFormatter = ({ data }) => {
    if (!actionSummaryMap[data.action]) {
      return variableToSentenceTitleCase(data.action)
    }

    const actionData = data[data.action]
    return actionSummaryMap[data.action](actionData)
  }

  const commonColumns = [
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      cellRenderer: 'agGroupCellRenderer',
      valueFormatter: (params: { value: string }) => variableToSentenceTitleCase(params.value),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: actions.map(a => a.action),
      }
    },
    {
      hide: !showAll,
      field: 'address',
      headerName: 'Address',
      width: 350,
      filter: 'agTextColumnFilter'
    },
    { field: 'createdAt', headerName: 'Timestamp', sortable: true, width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
    { field: 'summary', resizable: true, headerName: 'Condensed Summary', width: 400, valueFormatter: ActionSummaryFormatter },

    { field: 'email', headerName: 'eMail',
      sortable: false, width: 140,
      cellRenderer: ResultFieldLinkRenderer,
      cellRendererParams: {
        prefix: '/admin/userprofile',
        object: 'user',
        field: 'email',
        linkField: 'userId'
      }
    },

    { field: 'first', headerName: 'First',
      sortable: false, width: 140,
      cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'user', field: 'firstName'}
    },

    { field: 'last', headerName: 'Last',
      sortable: false, width: 140,
      cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'user', field: 'lastName'}
    },

    { hide: true, field: 'id', headerName: 'Admin History Id', width: 250, filter: 'agTextColumnFilter' },
    {
      hide: !showAll,
      field: 'propertyId',
      headerName: 'Property',
      cellRenderer: LinkRenderer,
      cellRendererParams: { prefix: '/admin/propertyedit' },
      filter: 'agTextColumnFilter',
    }
  ]

  return (
      <div style={{ ...containerStyle}}>
        <ClaimNavigation onPropertySelect={navigateProperty} onUserSelect={navigateUser}/>

        <GridListHeader title="Admin Change Log" onRefresh={undefined} children={undefined} />
        {!showAll && <div className="mb-3">
          <PropertySummaryCard propertyId={propertyId} />
        </div>}
        <div style={{...gridStyle}} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            animateRows={true}
            masterDetail={true}
            // @ts-ignore
            columnDefs={commonColumns}
            detailCellRenderer={AdminHistoryDetailRenderer}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            serverSideInfiniteScroll={true}
            sideBar={sideBar}
            detailRowAutoHeight={true}
          />
        </div>
      </div>
  )
}

export default AdminHistoryList
