import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHouseUser, faQuestion, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { Property, ListingStatus, MlsStatuses, MLS_STATUS_LABELS } from '@bluebid-sdk/core'
import { getPropertyBranding, isSuccessResponse } from '@bluebid-sdk/api-client'
import { BlockUI } from '@bluebid-sdk/react-components'

import { useApiContext } from '../../../contexts'
import { checkListingStatus, updateMlsStatus } from '../../../lib/api'
import { relativeDate } from '../../../lib/utils'
import { AdminSecondaryActionButton } from '../../../components/adminActions/common'
import { AdminLabel } from './Styles'
import { successToast, errorToast } from '../../../utils/common'
import { useProperty } from '../hooks/useProperty'

export const MarketStatus: React.FC<{ property: Property; onChange?: () => void }> = ({ property, onChange }) => {
  const { apiClient } = useApiContext()
  const [loading, setLoading] = useState(false)
  const [listings, setListings] = useState<ListingStatus[]>()
  const [freshProperty, setFreshProperty] = useState(property)
  const { refetchProperty } = useProperty(freshProperty.propertyId)
  const [mlsStatus, setMlsStatus] = useState(freshProperty.mlsStatus)
  const [zillowNameLoading, setZillowNameLoading] = useState(false)
  const [zillowName, setZillowName] = useState<string>()

  useEffect(() => {
    // if not user edited
    if (!freshProperty.mlsEdited) {
      // if it's never been checked, update it
      if (!freshProperty.mlsCheckedAt) {
        checkMLS()
      } else {
        // it has been checked. Ensure we are past 24 hours since last check
        const lastChecked = moment(freshProperty.mlsCheckedAt)
        const now = moment().startOf('day')
        if (lastChecked.diff(now, 'days') > 0) {
          checkMLS()
        }
      }
    }

    setZillowNameLoading(true)
    setZillowName(undefined)

    apiClient
      .callFn(getPropertyBranding, { property: freshProperty })
      .then((response) => {
        if (isSuccessResponse(response)) {
          setZillowName(response.data?.realtorName)
        }
      })
      .finally(() => {
        setZillowNameLoading(false)
      })
  }, [freshProperty?.propertyId])

  const changeMlsStatus = async (newMlsStatus?: MlsStatuses) => {
    setMlsStatus(newMlsStatus)
    setLoading(true)

    const response = await apiClient.callFn(updateMlsStatus, {
      propertyId: freshProperty.propertyId,
      mlsStatus: newMlsStatus,
      userEdited: !!newMlsStatus,
    })

    if (isSuccessResponse(response)) {
      successToast('MLS Status updated')
      setFreshProperty(response.data)
      refetchProperty()
      onChange?.()
    } else {
      errorToast(response.errorMessage)
    }

    setLoading(false)
  }

  const checkMLS = async () => {
    setListings(undefined)
    setLoading(true)

    const listingsResponse = await apiClient.callFn(checkListingStatus, { address: freshProperty.address })

    if (isSuccessResponse(listingsResponse)) {
      setListings(listingsResponse.data)

      const exactMatch = listingsResponse.data?.filter((l) => l.matchResult.matchConfidence === 1)?.[0]

      const newMlsStatus = exactMatch
        ? exactMatch.onMarket
          ? MlsStatuses.Listed
          : MlsStatuses.OffMarket
        : MlsStatuses.NoMatch

      const mlsStatusResponse = await apiClient.callFn(updateMlsStatus, {
        propertyId: freshProperty.propertyId,
        mlsStatus: newMlsStatus,
        userEdited: false,
      })

      if (isSuccessResponse(mlsStatusResponse)) {
        const p = mlsStatusResponse.data

        if (p) {
          setFreshProperty(p)

          if (p.mlsStatus !== mlsStatus) {
            setMlsStatus(p.mlsStatus)
            successToast('MLS Status updated')
            onChange?.()
          }
        }

        refetchProperty()
      } else {
        errorToast(mlsStatusResponse.errorMessage)
      }
    } else {
      errorToast(`MLS Status Error: ${listingsResponse.errorMessage}`)
    }

    setLoading(false)
  }

  const ItemIcon = ({ match }: { match: boolean }) => {
    return (
      <>
        {match && <FontAwesomeIcon icon={faCheck} />}
        {!match && <FontAwesomeIcon icon={faQuestion} />}
      </>
    )
  }

  return (
    <Card style={{ width: '100%' }}>
      <CardBody>
        <BlockUI blocking={loading} />
        <Row>
          <Col xs={6}>
            <Label className="me-1" for="mlsStatusLabel">
              <h5>MLS Status</h5>
            </Label>
          </Col>
          <Col xs={6} className="text-end">
            {!zillowNameLoading && (
              <span className="small">
                searching with Zillow name: {zillowName ? <b>{zillowName}</b> : <i>(none)</i>}
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <AdminSecondaryActionButton disabled={freshProperty.mlsEdited} onClick={() => checkMLS()}>
              <FontAwesomeIcon icon={faHouseUser} /> Check MLS Status
            </AdminSecondaryActionButton>
          </Col>
          <Col className={'text-end float-end'} xs={3}>
            <MLSStatusInput
              type="select"
              name="mlsStatusLabel"
              id="mlsStatusLabel"
              value={mlsStatus}
              onChange={(e) => changeMlsStatus(e.target.value as any)}
            >
              <option value="">(clear / reset)</option>
              <option value={MlsStatuses.NoMatch}>{MLS_STATUS_LABELS['no-match']}</option>
              <option value={MlsStatuses.OffMarket}>{MLS_STATUS_LABELS['off-market']}</option>
              <option value={MlsStatuses.Listed}>{MLS_STATUS_LABELS.listed}</option>
            </MLSStatusInput>
          </Col>
          <Col xs={5} className="text-end">
            {!!freshProperty.mlsEdited && (
              <TimeStamp>
                Manually Set <b>{relativeDate(freshProperty.mlsCheckedAt)}</b>
              </TimeStamp>
            )}
            {!freshProperty.mlsEdited && (
              <TimeStamp>
                Last Updated:{' '}
                <b>{freshProperty.mlsCheckedAt ? relativeDate(freshProperty.mlsCheckedAt) : '(not checked)'}</b>
              </TimeStamp>
            )}
          </Col>
        </Row>
        {listings?.length > 0 && (
          <div className="mt-3">
            {listings.map((listing, index) => (
              <Row key={`listing-result-${index}`}>
                <AdminLabel xs={2}>Address:</AdminLabel>
                <Address xs={7}>
                  <a href={`${listing.link}`} rel="noreferrer" target="_blank" className="text-decoration-none">
                    {listing.address}
                  </a>
                </Address>
                <Status xs={3} listed={listing.onMarket ? 'true' : 'false'}>
                  <ItemIcon match={listing.matchResult.matchConfidence === 1} />{' '}
                  {`${listing.onMarket ? 'LISTED' : 'Off-Market'}`} (
                  {Math.round(listing.matchResult.matchConfidence * 100)}%)
                  {listing.matchResult?.misMatches?.length > 0 && (
                    <div className="d-inline-block">
                      <StyledPopover placement="top" target={`listing-match-result-${index}`} trigger="focus">
                        <PopoverHeader>Match Details</PopoverHeader>
                        <PopoverBody className="pt-2">
                          <p className="mb-2">
                            <b>
                              <i>{listing.address}</i>
                            </b>
                          </p>
                          <p className="mt-1 mb-2">
                            <b>{listing.matchResult.matches?.length}</b> out of{' '}
                            <b>{listing.matchResult.comparisonFields.length}</b> fields match (
                            <b>{Math.round(listing.matchResult.matchConfidence * 100)}%</b>)
                          </p>

                          <Label className="d-block text-decoration-underline">Mismatches</Label>
                          <ListGroup numbered>
                            {listing.matchResult?.misMatches.map((misMatch, misMatchIndex) => (
                              <ListGroupItem
                                key={`listing-mismatchmatch-${index}-${misMatchIndex}`}
                                className="text-nowrap"
                              >
                                <b>{misMatch.field}</b>: "{misMatch.value1}" !== "{misMatch.value2}"
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </PopoverBody>
                      </StyledPopover>
                      <FontAwesomeIcon
                        id={`listing-match-result-${index}`}
                        style={{ outline: 'none', cursor: 'pointer', marginLeft: '3px' }}
                        icon={faInfoCircle}
                      />
                    </div>
                  )}
                </Status>
              </Row>
            ))}
          </div>
        )}
        {listings?.length === 0 && (
          <div className="mt-3">
            <i>(no results)</i>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

const Address = styled(Col)``

const Status = styled(Col)<{ listed: string }>`
  color: ${(props) => (props.listed === 'true' ? 'red' : 'green')};
  font-weight: ${(props) => (props.listed === 'true' ? 'bold' : 'normal')};
  white-space: nowrap;
`

const MLSStatusInput = styled(Input)`
  font-family: Roboto, sans-serif;
  margin-bottom: 4px;
  font-size: 11pt;
  color: rgb(22, 108, 197);
  padding: 4px 10px;
`

const TimeStamp = styled.span`
  margin-top: 4px;
  display: inline-block;
`

const StyledPopover = styled(UncontrolledPopover)`
  .popover {
    max-width: 100% !important;
  }

  .list-group-item + .list-group-item {
    border-top-width: 1px;
  }
`
