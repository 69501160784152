import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import styled from 'styled-components'
import { Col, Row } from 'reactstrap'

import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { GridContainerStyle, GridStyle } from '../../components/CommonGrid'
import { GridListHeader } from '../../views/admin/GridListHeader'
import { assetsGridColumns } from './assets-grid-columns.fn'
import { GridComponent } from '../grid'

const ImageAssetMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/bmp', 'image/gif', 'image/heic']

const VideoAssetMimeTypes = ['video/mp4', 'video/wav', 'text/csv']

const Datum = styled.div`
  font-weight: bold;
`

const AssetCellRenderer = (params) => {
  const { data } = params

  const isImage = data?.mimeType && ImageAssetMimeTypes.includes(data?.mimeType)
  const isVideo = data?.mimeType && VideoAssetMimeTypes.includes(data?.mimeType)

  return (
    <>
      <Row>
        <Col md={5}>
          {isImage && <img src={data.url} width={400} />}
          {isVideo && <video src={data.url} width={400} controls />}
        </Col>
        <Col md={7}>
          <Row>
            <Col md={2}>
              <Datum>Width</Datum>
            </Col>{' '}
            <Col md={6}>{data.width || '-'}</Col>
          </Row>
          <Row>
            <Col md={2}>
              <Datum>Height</Datum>
            </Col>{' '}
            <Col md={6}>{data.height || '-'}</Col>
          </Row>
          <Row>
            <Col md={2}>
              <Datum>Mime Type</Datum>
            </Col>{' '}
            <Col md={6}>{data.mimeType || '-'}</Col>
          </Row>
          <Row>
            <Col md={2}>
              <Datum>Size</Datum>
            </Col>{' '}
            <Col md={6}>{data.fileSize || '-'}</Col>
          </Row>
          <Row>
            <Col md={2}>
              <Datum>Order</Datum>
            </Col>{' '}
            <Col md={6}>{data.order || '-'}</Col>
          </Row>
          <Row>
            <Col md={2}>
              <Datum>URL</Datum>
            </Col>
            <Col md={6}>
              <a href={data.url} target={'_blank'}>
                {data.url}
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export const AssetsGridComponent: React.FC<{
  title?: string
  userId?: string
  columnOrder?: string[]
  sessionKey?: string
}> = ({ title = 'Assets', userId, columnOrder = [], sessionKey = 'assets' }) => {
  const gridRef = useRef<AgGridReact>()

  // never changes, so we can use useMemo
  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])
  // detail renderer for images
  const detailCellRenderer = useMemo(() => {
    return AssetCellRenderer
  }, [])

  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])
  //needed for serverSide data model
  const getRowId = useCallback((params) => params.data.id, [])

  const [query, setQuery] = useState<string>()

  const datasource = useMemo(
    () => new Datasource({ apiCaller: api, index: 'assets', adminSearch: true, fields: 'owner,property', query }),
    [query]
  )

  datasource.addTypeMapper('set', 'mimeType', (fld, filter) => {
    return `${fld}:(${filter.values.map((v) => `"${v}"`).join(' OR ')})`
  })

  useEffect(() => {
    setQuery(userId ? `ownerId:"${userId}"` : undefined)
  }, [userId])

  const columnDefs = useMemo(() => {
    const columns = assetsGridColumns()

    return [
      ...(columnOrder?.length
        ? columnOrder
            .map((headerName) => columns.find((column) => column.headerName === headerName))
            .filter((column) => column)
        : columns),
    ]
  }, [columnOrder])

  return (
    <>
      {!!title && <GridListHeader title={'Assets'} onRefresh={onBtnRefresh} />}

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          <GridComponent
            gridRef={gridRef}
            sessionKey={sessionKey}
            masterDetail={true}
            columnDefs={columnDefs}
            detailCellRenderer={detailCellRenderer}
            serverSideDatasource={datasource}
            getRowId={getRowId}
            enableRangeSelection={true}
          />
        </div>
      </div>
    </>
  )
}
