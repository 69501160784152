import { Group } from '@bluebid-sdk/core'

import { api } from '../api.fn'

export function updateGroup(id: string, group: Partial<Group>) {
  return api(`/groups/${id}`, {
    method: 'PUT',
    body: group
  })
}
