import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Col, Row, FormGroup, Label, Input, ButtonGroup, Button, Spinner } from 'reactstrap'
import styled from 'styled-components'

import { Property, parsePropertyAddress } from '@bluebid-sdk/core'
import { isErrorResponse } from '@bluebid-sdk/api-client'
import { PrimaryButton as NewPrimaryButton } from '@bluebid-sdk/react-components'

import { api, uploadAsset } from '../lib/api'
import { successToast, errorToast } from '../utils/common'
import { addPropertyAssets } from '../lib/data/bluebidData'
import { capitalizeEveryFirst } from '../lib/utils'
import { onImageDrop } from '../lib/images'

enum AttributionType {
  TEXT = 'text',
  GOOGLE = 'google',
}
enum AttributionPosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
}
enum AttributionColor {
  BLUE_WHITE = 'blue-white',
  WHITE_BLUE = 'white-blue',
}
type Attribution = {
  type: AttributionType
  position?: AttributionPosition
  color?: AttributionColor
  label?: string
}

export const PhotoAttribution: React.FC<{ property: Property; onSuccess?: () => void }> = ({ property, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [photoAttributionFiles, setPhotoAttributionFiles] = useState<
    (File & { preview: string; width?: number; height?: number; fileSize?: number })[]
  >([])
  const [attribution, setAttribution] = useState<Attribution>({
    type: AttributionType.GOOGLE,
    position: AttributionPosition.BOTTOM_LEFT,
  })
  const [expandAttributionOptions, setExpandAttributionOptions] = useState(false)
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/heic': [],
      'image/*': [],
      'video/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const newFiles = await onImageDrop(acceptedFiles)
      setPhotoAttributionFiles(newFiles)
    },
  })

  const isUploadPhotoAttributionDisabled =
    isLoading ||
    !photoAttributionFiles?.length ||
    !attribution.type ||
    !attribution.position ||
    (attribution.type === AttributionType.TEXT && !attribution.label)

  const deletePhotoAttributionFile = (index: number) => {
    const files = photoAttributionFiles.slice()
    files.splice(index, 1)
    setPhotoAttributionFiles(files)
  }

  const handleAttributionChange = (field: string, value: any) => {
    const updatedAttribution: Attribution = { ...attribution }

    if (field === 'type') {
      if (value === AttributionType.TEXT) {
        const address = parsePropertyAddress(property)
        const city = capitalizeEveryFirst(address?.city)
        const state = address?.state?.toUpperCase()

        updatedAttribution.position = AttributionPosition.BOTTOM_RIGHT
        updatedAttribution.color = AttributionColor.BLUE_WHITE
        updatedAttribution.label = `Source: Town of ${city}, ${state}`
      } else if (value === AttributionType.GOOGLE) {
        updatedAttribution.position = AttributionPosition.BOTTOM_LEFT

        delete updatedAttribution.color
        delete updatedAttribution.label
      }
    }
    updatedAttribution[field] = value

    setAttribution(updatedAttribution)
  }

  const handleUploadPhotoAttribution = async () => {
    if (isUploadPhotoAttributionDisabled) {
      return
    }

    setIsLoading(true)

    const successfulUrls: [number, string][] = []
    let index = 0

    for (const imageFile of photoAttributionFiles) {
      const uploadResponse = await uploadAsset(imageFile)

      if (isErrorResponse(uploadResponse)) {
        errorToast(uploadResponse.errorMessage)
      } else {
        const { url } = uploadResponse.data

        const attributionResponse = await api('/images/addAttribution', {
          method: 'POST',
          body: {
            url,
            removeOriginal: true,
            attribution,
          },
        })

        if (isErrorResponse(attributionResponse)) {
          errorToast(attributionResponse.errorMessage)
        } else {
          successfulUrls.push([index, attributionResponse.data.value.url])
        }
      }

      index++
    }

    if (!successfulUrls.length) {
      setIsLoading(false)
      return
    }

    const attributedAssets = successfulUrls.map(([index, url]) => {
      let file = photoAttributionFiles[index]
      return {
        url,
        mimeType: file.type,
        width: file.width || undefined,
        height: file.height || undefined,
        fileSize: file.fileSize || undefined,
      }
    })

    // add the asset entries
    const result = await addPropertyAssets({
      propertyId: property.propertyId,
      ownerId: property.ownerId,
      assets: attributedAssets,
    })

    if (result?.length) {
      successToast(`Attributed and uploaded successfully!`)
    } else {
      setIsLoading(false)
      errorToast(`Sorry, there was a problem adding attributed photos as assets`)
      return
    }

    setPhotoAttributionFiles([])
    setIsLoading(false)
    onSuccess?.()
  }
  return (
    <div className="position-relative">
      <ProcessingOverlay show={isLoading} />
      <ImageLabel className="mt-3">Photo Attribution</ImageLabel>

      <FormGroup>
        <Label>Type</Label>
        <Row>
          <Col>
            <ButtonGroup className="d-block" size="sm">
              <Button
                color="primary"
                outline
                active={attribution.type === AttributionType.GOOGLE}
                onClick={() => handleAttributionChange('type', AttributionType.GOOGLE)}
              >
                Google
              </Button>
              <Button
                color="primary"
                outline
                active={attribution.type === AttributionType.TEXT}
                onClick={() => handleAttributionChange('type', AttributionType.TEXT)}
              >
                Adhoc
              </Button>
            </ButtonGroup>
          </Col>
          <Col>
            <Button
              style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
              color="link"
              onClick={() => setExpandAttributionOptions(!expandAttributionOptions)}
            >
              [{!expandAttributionOptions ? '+' : '-'}] advanced options
            </Button>
          </Col>
        </Row>
      </FormGroup>
      {!!expandAttributionOptions && (
        <>
          <FormGroup>
            <Label>Position</Label>
            {[
              AttributionPosition.TOP_LEFT,
              AttributionPosition.TOP_RIGHT,
              AttributionPosition.BOTTOM_RIGHT,
              AttributionPosition.BOTTOM_LEFT,
            ].map((value) => (
              <FormGroup check>
                <Input
                  name="position"
                  type="radio"
                  value={value}
                  onChange={() => handleAttributionChange('position', value)}
                  checked={attribution.position === value}
                />{' '}
                <Label check onClick={() => handleAttributionChange('position', value)}>
                  {value}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
          {attribution.type === AttributionType.TEXT && (
            <>
              <FormGroup>
                <Label>Color</Label>
                {[AttributionColor.BLUE_WHITE, AttributionColor.WHITE_BLUE].map((value) => (
                  <FormGroup check>
                    <Input
                      name="color"
                      type="radio"
                      value={value}
                      onChange={() => handleAttributionChange('color', value)}
                      checked={attribution.color === value}
                    />{' '}
                    <Label check onClick={() => handleAttributionChange('color', value)}>
                      {value === AttributionColor.BLUE_WHITE && <span>Blue background / White text</span>}
                      {value === AttributionColor.WHITE_BLUE && <span>White background / Blue text</span>}
                    </Label>
                  </FormGroup>
                ))}
              </FormGroup>
              <FormGroup>
                <Label for="label">Label</Label>
                <Input
                  type="text"
                  name="label"
                  id="label"
                  value={attribution?.label}
                  onChange={(e) => handleAttributionChange(e.target.name, e.target.value)}
                />
              </FormGroup>
            </>
          )}
        </>
      )}

      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        Drag photos here
        {photoAttributionFiles?.map((file, index) => (
          <div className="position-relative mt-3">
            <DeletePhotoBtn
              onClick={() => {
                deletePhotoAttributionFile(index)
              }}
            >
              &times;
            </DeletePhotoBtn>
            <img className="img-thumbnail" src={file.preview} alt="" />
          </div>
        ))}
      </div>

      <div className="mt-3 text-end">
        <NewPrimaryButton
          width={100}
          height={38}
          onClick={handleUploadPhotoAttribution}
          disabled={isUploadPhotoAttributionDisabled}
        >
          {isLoading && <Spinner size="sm" className="mr-1" />}
          Upload
        </NewPrimaryButton>
      </div>
    </div>
  )
}

const ImageLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
`
const DeletePhotoBtn = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  color: #007fcc;
  font-size: 21px;
  font-weight: 600;
  padding: 0 5px;
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #ddd;
  line-height: 24px;
`
const ProcessingOverlay = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
`
