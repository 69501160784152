import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  Card,
  CardTitle,
  CardText,
  Table
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser';
import { JsonView } from 'react-json-view-lite'

import { Outcome, isOk } from '@bluebid-sdk/core'
import { PrimaryButton as NewPrimaryButton, SecondaryButton as NewSecondaryButton } from '@bluebid-sdk/react-components'

import { startCase } from '../../utils/common'
import { formatDate } from '../../lib/utils'

type InquiryResultsModalProps = {
  isOpen: boolean
  results: Record<string, Outcome<unknown, unknown>>
  onClose: () => void
}

export const InquiryResultsModal: React.FC<InquiryResultsModalProps> = ({
  isOpen,
  results,
  onClose
}) => {
  const getIcon = (channel) => {
    switch (channel) {
      case 'email':
        return <FontAwesomeIcon icon={faEnvelope} />
      case 'sms':
        return <FontAwesomeIcon icon={faPhone} />
      case 'anywhere':
        return <FontAwesomeIcon icon={faAsterisk} />
      default:
        return null
    }
  }

  const openPopup = (body) => {
    const popup = window.open('', '_blank', 'width=1024,height=768')
    popup.document.write(body)
    popup.document.close()
  }

  const renderSuccessContent = (name, value) => {
    switch (name) {
      case 'email':
        return (
          <Table>
            <tbody>
              <tr>
                <td width="100px"><strong>To</strong></td>
                <td>{value.recipientName} - {value.recipientEmail}</td>
              </tr>
              <tr>
                <td><strong>Date</strong></td>
                <td>{formatDate(value.dateSent)}</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <NewPrimaryButton onClick={() => openPopup(value.body)}>View</NewPrimaryButton>
                </td>
              </tr>
            </tbody>
          </Table>
        )
      case 'sms':
        return (
          <Table>
            <tbody>
              <tr>
                <td width="100px"><strong>To</strong></td>
                <td>{value.recipientName} - {value.recipientPhone}</td>
              </tr>
              <tr>
                <td><strong>Date</strong></td>
                <td>{formatDate(value.dateSent)}</td>
              </tr>
              <tr>
                <td><strong>Message</strong></td>
                <td>
                  {parse(value?.body?.replace(/\\n/g, '<br>').replace(/"/g, '') || '<p>empty</p>')}
                </td>
              </tr>
            </tbody>
          </Table>
        )
      case 'anywhere':
      case 'referral':
        return (
          <JsonView
            data={JSON.parse(value.referralSource)}
            shouldExpandNode={() => true} />
          // <textarea
          //   readOnly
          //   rows={5}
          //   className='form-control'
          //   disabled={true}
          //   defaultValue={value.referralSource || 'Skipped'}
          // />
        )
      default:
        return null
    }
  }

  const renderErrorContent = (error) => (
    <div>
      <CardText style={{color: 'red'}}>Error Message: {error.errorMessage}</CardText>
      <pre>
        {error.body}
      </pre>
    </div>
  )

  return (
    <Modal isOpen={isOpen} size="xl" backdrop={true} keyboard={true} toggle={onClose}>
      <ModalHeader toggle={onClose}>Inquiry Results</ModalHeader>
      <ModalBody>
        {Object.entries(results).map(([name, v], index) => {
          const value = isOk(v) ? v.value : undefined
          const error = !isOk(v) ? v.error : undefined

          const status = isOk(v) ? 'SENT' : 'NOT SENT'
          const statusColor = isOk(v) ? 'text-success' : 'text-danger'

          // backwards compatibility; hide referral channel results if it was skipped
          if ((name === 'referral' || name === 'anywhere') && (value && value['skipped'])) {
            return null
          }

          return (
            <Card key={index} className='my-2'>
              <CardBody>
                <div className='d-flex align-items-center mb-2'>
                  {getIcon(name)}
                  <div className='ml-3'>
                    <CardTitle tag='h5' className='mb-0'>
                      {startCase(name)}
                    </CardTitle>
                  </div>
                  <div className='ml-3'>
                    <CardText className={statusColor}>{status}</CardText>
                  </div>
                </div>
                <div>
                  {isOk(v) ? renderSuccessContent(name, value) : renderErrorContent(error)}
                </div>
              </CardBody>
            </Card>
          )
        })}
      </ModalBody>
      <ModalFooter>
        <NewSecondaryButton onClick={onClose}>Close</NewSecondaryButton>
      </ModalFooter>
    </Modal>
  )
}
