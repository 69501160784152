import { v4 as uuidv4 } from 'uuid'

import { ApiResponse, isErrorResponse } from '@bluebid-sdk/api-client'

import { api } from '../api.fn'
import { s3Upload } from './s3-upload.fn'

export async function uploadAsset(
  file: File,
  assetPrefix?: string
): Promise<ApiResponse<{ url: string }>> {
  const fileName = file.name
  const fileType = file.type
  const fileExt = fileType?.split('/')?.pop()?.trim()
  const uuid = uuidv4()
  const assetName = `${assetPrefix ? `${assetPrefix}_` : ''}${uuid}.${fileExt}`

  const signedUrlResponse = await api<{ signedUrl: string }>('/sign', {
    method: 'POST',
    body: {
      fileName,
      fileType,
      assetName,
    },
  })

  // return the error if not successful
  if (isErrorResponse(signedUrlResponse) || !signedUrlResponse?.data?.signedUrl) {
    return signedUrlResponse as ApiResponse
  }

  return s3Upload(signedUrlResponse.data.signedUrl, file, fileType)
}
