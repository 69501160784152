import React, { useCallback, useMemo, useRef } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ModuleRegistry } from '@ag-grid-community/core'

import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { GridContainerStyle, GridStyle, StandardColumnDefs } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import { useSessionStorage } from '../../lib/utils'
import confirm from '../../components/Confirm'
import apiCall from '../../lib/data/apiCall'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { restoreGridState } from '../../components/grid/GridRenderers'
import { GridListHeader } from './GridListHeader'
import { invitesGridColumns } from '../../components'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])

const deleteInviteItem = async (id) => {
  const result = await confirm({
    title: 'Delete Invitation',
    message: 'Are you sure you want to delete this invitation?',
    confirmText: 'Yes',
    confirmColor: 'danger',
  })

  if (result) {
    await apiCall(`/opportunities/${id}`, {
      method: 'DELETE',
    })
  }
}

/**
 * @deprecated use ZipDetailInvites
 */
const CityDetailInvites = ({ state, city }) => {
  const gridRef = useRef()

  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => GridStyle, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const datasource = useMemo(() => {
      return new Datasource({
        apiCaller: api,
        index: 'invites',
        query: `city:${city} AND state:${state}`,
        adminSearch: true,
      })
    }
    , [city, state])

  const [colState, setColState] = useSessionStorage('cityInvites', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef),[])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(),[])

  const getRowId = useCallback(function(params) {
    return params.data.inviteId
  }, [])

  const handleDelete = async (id) => {
    deleteInviteItem(id).then(() => {
      setTimeout(() => {
        gridRef.current.api.refreshInfiniteCache()
      }, 300)
    })
  }

  const columnDefs = useMemo(
    () =>
      invitesGridColumns({
        onDelete: handleDelete,
      }),
    [],
  )

  return (
    <div style={{ ...containerStyle, height: '300px' }}>
      <GridListHeader title={'City Invites'} onRefresh={onBtnRefresh} />
      <div style={{ ...gridStyle, height: 'calc(100% - 70px)'}} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
              onGridReady={onGridReady}
              onSortChanged={saveGridState}
              onColumnMoved={saveGridState}
              onColumnResized={saveGridState}
              onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            getRowId={getRowId}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection='true'
            rowSelection='multiple'
            rowModelType='serverSide'
            serverSideDatasource={datasource}
            serverSideInfiniteScroll={true}
            suppressRowClickSelection='true'
          />
        </div>
    </div>
  )
}

export default withAuthenticationRequired(CityDetailInvites, {
  onRedirecting: () => <Loading />,
})
