import { api } from '../api.fn'

export function assignUserToGroup(id: string, userId: string) {
  return api(`/groups/${id}/users`, {
    method: 'POST',
    body: {
      userId
    }
  })
}
