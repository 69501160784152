/**
 * Auth0 configuration for stage
 */
export const getConfig = () => getEnv().auth
export const getApiHost = () => getEnv().host

export const getEnv = () => {
    const StageConfig = {
        scope: 'browse',  // same for all
        domain: 'auth-staging.bluebid.app',
        clientId: 'nsd2xrxSrvjURhPgRz8gLM6OACKcfRCd',
        audience: 'https://staging.bluebid.io', // API audience
        appOrigin: 'https://stage-admin.bluebid.app',
    }
    return {
        desc: 'Stage API',
        auth: StageConfig,
        host: StageConfig.audience,
        appHost: 'https://staging.bluebid.app'
    }
}
