/**
 * *** DO NOT MODIFY FILE ***
 * *** copy of api.fn.ts from desktop ***
 * @todo consolidate all api functions into @bluebid-sdk/api-client
 */
import { ApiResponse } from '@bluebid-sdk/api-client'

import { getApiHost, getConfig } from '../../config'

const BLUEBID_API_TOKEN = process.env.REACT_APP_BLUEBID_API_TOKEN
const LOG_API_CALLS = process.env.REACT_APP_LOG_API_CALLS === 'true'
const AUTH_TOKEN_KEY = 'token'
const config = getConfig()
const errorCodeRegex = /4[0-9][0-9]/

const log = (...args) => {
  console.log('[DEBUG][apiCall]', ...args)
}

const assertPath = (path) => {
  const type = typeof path
  if (type !== 'string') {
    throw new TypeError(`The path should be a string, instead received a ${type}`)
  }
}

const parseResponse = async (res: Response) => {
  // check user has invalid token or token expired!
  if (errorCodeRegex.test(res.status?.toString())) {
    auth0LogOutAPICall()
  }

  // If a body response exists, parse and extract the possible properties
  let response: ApiResponse = res.status === 204 ? {} : await res.json()

  // handle BlueBid API return results
  LOG_API_CALLS && console.log('Api response: ', response)

  return response
}

const auth0LogOutAPICall = async () => {
  try {
    if (config) {
      localStorage.clear()
      sessionStorage.clear()
      const url = `https://${config.domain}/v2/logout?returnTo=${config.appOrigin}&client_id=${config.clientId}`

      const link = document.createElement('a')
      link.href = url
      // link.target = "_self";

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    }
  } catch (err) {
    console.error('err', err)
  }
}

/**
 * A wrapper for making Api calls. This wrapper is mostly designed for use in calling BlueBid API,
 * but it can be used for any URL api call.
 *
 * Samples of using this:
 *
 * // Simple GET request
 * request('/properties/id/fasdfas-fqerqrwf-fadfasf-fasdfasdf')
 *
 * // POST request for claiming
 * request('/properties', {
 *   method: 'POST',
 *   body: { ownerId: id,
 *          detail: { ... } }
 * })
 *
 * // GET request with custom headers
 * request('/secondary/posts', {
 *   headers: {
 *     'Accept-Language': 'it'
 *   }
 * })
 */
export const api = async <T = any>(
  path: string,
  options: {
    headers?: HeadersInit
    host?: string
    query?: string
    method?: string
    body?: any
  } = {}
): Promise<ApiResponse<T>> => {
  let token: string, bbHeaders
  let clientId = document.cookie.replace(/(?:(?:^|.*;\s*)_ga\s*\=\s*([^;]*).*$)|^.*$/, '$1')
  if (!options.host) {
    // There are two tokens being used:
    // 1. the insecure token used for unauthenticated users. This is a hard-coded token that provides a very basic level
    //    of api protection
    // 2. an access token obtained from Auth0 after a user has logged in. This token, if present, will overwrite the
    //    first token

    let accessToken = localStorage.getItem(AUTH_TOKEN_KEY)
    token = BLUEBID_API_TOKEN
    bbHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(token && { Authorization: `${token}` }),
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(clientId && { XClientId: `${clientId}` }),
    }
  }

  const { headers, query = null, method, body, host = getApiHost(), ...extraOpts } = options
  assertPath(path)

  // Compose the request configuration object
  const reqOptions: RequestInit = {
    method,
    headers: {
      ...bbHeaders,
      ...headers,
    },
    ...extraOpts,
  }

  // If a body object is passed, automatically stringify it.
  if (body) {
    if (body._parts) {
      reqOptions.body = body
    } else {
      reqOptions.body = typeof body === 'object' ? JSON.stringify(body) : body
    }
  }

  let queryString = ''
  if (query) {
    // Convert to encoded string and prepend with ?
    queryString = new URLSearchParams(query).toString()
    queryString = queryString && `?${queryString}`
  }

  let URL = `${host}${path}${queryString}`
  LOG_API_CALLS && log(URL, reqOptions, body || {})
  return fetch(URL, reqOptions).then(parseResponse)
}
