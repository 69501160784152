import { useEffect, useState } from 'react'

import { ClaimAlert } from '@bluebid-sdk/core'
import { getClaimAlerts } from '@bluebid-sdk/api-client'

import { useApiContext } from '../../../contexts'

export const useListingAlerts = (userId: string) => {
  const { apiClient } = useApiContext()
  const [alerts, setAlerts] = useState<ClaimAlert[]>([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const getListingAlerts = (userId: string) => {
    setLoading(true)
    apiClient.callFn(getClaimAlerts, { userId }).then((result) => {
      setAlerts(result?.data)
      setLoading(false)
    })
  }

  useEffect(() => getListingAlerts(userId), [])

  return {
    alerts,
    error,
    loading,
  }
}
