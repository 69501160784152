import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, Progress } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

import { Property, User } from '@bluebid-sdk/core'
import { PrimaryButton as NewPrimaryButton, SecondaryButton as NewSecondaryButton } from '@bluebid-sdk/react-components'

import { errorToast, successToast } from '../../utils/common'
import { addPropertyAssets, getSignedUrl, s3upload } from '../../lib/data/bluebidData'
import { CenteredModalFooter, DialogFooterBlock, ModalSubHeader } from './ManagePhotosAction'
import { TagManager } from '../../lib/gtm/TagManager'
import { onImageDrop } from '../../lib/images'

type AddPhotosActionProps = {
  bluebidUser: User
  property: Property
  showModal: boolean
  onClose: () => void
  shouldUpdateProfile?: () => void
}
export const AddPhotosAction: React.FC<PropsWithChildren<AddPhotosActionProps>> = ({
  bluebidUser,
  property,
  showModal,
  onClose,
  shouldUpdateProfile,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [assets, setAssets] = useState([])
  const [uploadProgress, setUploadProgress] = useState([])

  const loggedIn = bluebidUser !== undefined
  const isClaimed = property?.ownerId !== undefined
  const isOwner = loggedIn && isClaimed && bluebidUser && property?.ownerId === bluebidUser.id

  useEffect(() => {
    // Track showing the modal
    if (showModal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_photos',
          action: 'User added photos',
        },
      })
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          // @ts-ignore
          page_location: window.location.pathname + '/addAssets',
        },
      })
    }
  }, [showModal])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/heic': [],
      'image/*': [],
      'video/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const newFiles = await onImageDrop(acceptedFiles)
      setAssets(newFiles)
    },
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => assets.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [assets])

  const resetPhotos = () => {
    onClose()
    setAssets([])
    setUploadProgress([])
  }

  const deleteAsset = (index) => {
    // delete assets code here
    const assetsArr = assets.slice()
    assetsArr.splice(index, 1)
    setAssets(assetsArr)
  }

  const uploadAssets = React.useCallback(async () => {
    try {
      setIsLoading(true)
      let dte = new Date()
      let ticks = dte.getTime()
      let progress = new Array(assets.length)
      setUploadProgress(progress)
      let responses: any[] = await Promise.all(
        assets.map((file, ndx) => {
          return new Promise((resolve, reject) => {
            let assetName = `${ticks}-${file.name}`
            getSignedUrl(file.name, file.type, assetName).then((data) => {
              if (data.signedUrl) {
                s3upload(data.signedUrl, file.type, file, (p) => {
                  progress[ndx] = p.progress
                  setUploadProgress([])
                  setUploadProgress(progress)
                }).then((response) => {
                  if (response) {
                    resolve(response)
                  } else {
                    errorToast(`Oops, something went wrong!`)
                    reject(data)
                  }
                  // console.log('progress', ndx + 1, assets.length);
                  // setUploadProgress(progress + 1);
                })
              } else {
                // console.error(data)
                // console.log('progress', ndx + 1, assets.length);
                reject(data)
                errorToast(`Oops, something went wrong - ${data?.errorMessage}`)
              }
            })
          })
        })
      )

      // step 2. add asset entries for all successfully uploaded assets
      let propertyAssets = responses.map((result, ndx) => {
        // console.log('result', result, ndx);
        let file = assets[ndx]
        return {
          url: result.url,
          mimeType: file.type,
          width: file.width || undefined,
          height: file.height || undefined,
          fileSize: file.fileSize || undefined,
        }
      })

      // Add the asset entries
      if (property?.propertyId && property?.ownerId) {
        addPropertyAssets({
          propertyId: property.propertyId,
          ownerId: property.ownerId,
          assets: propertyAssets,
        }).then((result) => {
          if (result?.length) {
            successToast(`Upload successful!`)
            shouldUpdateProfile?.()
            onClose()
            // resetPhotos();
          } else {
            console.error(result)
            errorToast(`Sorry, there was a problem adding photos!`)
            onClose()
            resetPhotos()
          }
        })
      }

      setIsLoading(false)
    } catch (error) {
      console.error('error', error)
      setIsLoading(false)
    }
  }, [assets, uploadProgress])

  const renderAssets = assets.map((file, index) => {
    let progress = null
    if (uploadProgress.length > 0) {
      progress = (
        <ProgressBarWrapper>
          <ProgressBar bar animated color="#007fcc" value={uploadProgress[index] || 0} max={100} />
        </ProgressBarWrapper>
      )
    }

    return (
      <ThumbnailWrapper key={index}>
        <DeleteIcon
          onClick={() => {
            deleteAsset(index)
          }}
        >
          &times;
        </DeleteIcon>
        <ThumbBox>
          {file?.type.toString().includes('video') ? (
            <video style={vdoStyle} controls>
              <source src={file.preview} id="video_here" />
              Your browser does not support HTML5 video.
            </video>
          ) : (
            <img src={file.preview} style={img} alt="" />
          )}
        </ThumbBox>
        {progress}
      </ThumbnailWrapper>
    )
  })

  // if (!isOwner) return null

  return (
    <React.Fragment>
      {children}
      <Modal
        size="xl"
        isOpen={showModal}
        backdrop="static"
        toggle={onClose}
        modalTransition={{ timeout: 200 }}
      >
        <ModalHeader>
          <div>
            <h3>Add Photos and Videos</h3>
            <ModalSubHeader>Upload photos and videos to your home detail page.</ModalSubHeader>
          </div>
        </ModalHeader>
        <ModalBody>
          {/* <BlockUI blocking={isLoading} /> */}
          <div className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <DropZoneText>
                Drag your home photos here, or click to select from your{' '}
                {isMobile ? 'device' : 'computer'}
              </DropZoneText>
              <aside style={thumbsContainer as any}>{renderAssets}</aside>
            </div>
          </div>
        </ModalBody>
        <CenteredModalFooter>
          <DialogFooterBlock>
            {assets.length ? (
              <NewPrimaryButton
                height={38}
                disabled={isLoading}
                onClick={async () => await uploadAssets()}
                style={{
                  margin: '0 10px 0 0',
                  minWidth: '160px',
                }}
              >
                Upload
              </NewPrimaryButton>
            ) : null}
            <NewSecondaryButton
              height={38}
              disabled={isLoading}
              onClick={() => resetPhotos()}
              style={{
                minWidth: '160px',
              }}
            >
              Cancel
            </NewSecondaryButton>
          </DialogFooterBlock>
        </CenteredModalFooter>
      </Modal>
    </React.Fragment>
  )
}
// AddPhotosAction.whyDidYouRender = true

const ProgressBarWrapper = styled.div`
  margin: 0 15px 0 15px;
`

const ProgressBar = styled(Progress)`
  height: 10px;
  width: 90%;
  border-radius: 4px;
`
const ThumbnailWrapper = styled.div`
  // border: 1px solid red;
`

const DeleteIcon = styled.button`
  top: 25px;
  color: #007fcc;
  position: relative;
  font-size: 21px;
  font-weight: 600;
  padding: 0 6px;
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #ddd;

  @media (max-width: 458px) {
    font-size: 17px;
    padding: 1px 4px;
    height: 20px;
    width: 20px;
  }
`
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
}

const ThumbBox = styled.div`
  //display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: 10px;
  width: 160px;
  height: 120px;
  padding: 4px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;

  @media (max-width: 458px) {
    width: 110px;
    height: 80px;
  }
`
const img = {
  display: 'block',
  width: '100%',
  height: '100%',
  paddingTop: 10,
}

const vdoStyle = {
  display: 'block',
  width: '100%',
  height: '100%',
}

const DropZoneText = styled.p`
  font-size: 1.1rem;
  color: #005ce0;
`
