import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColDef, ColGroupDef } from '@ag-grid-community/core'

import { Outcome, isOk } from '@bluebid-sdk/core'

import ErrorScreen from '../../components/ErrorScreen'
import Loading from '../../components/Loading'
import { applyBulkPropertyAction, getPropertiesByIds, getPropertiesIndexTimes, getPropertyBulkActions } from '../../lib/data/bluebidData'
import { waitForIndexing } from '../../lib/data/waitForIndexing'
import { AddressLinkRenderer, CurrencyRenderer, DateValueFormatter, LinkRenderer } from '../../components/grid/GridRenderers'
import { GridListHeader, HeaderActionIcon } from './GridListHeader'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Table, Row, Col } from 'reactstrap'
import bulkEditForms from './bulk-edit-forms'
import { errorToast, successToast } from '../../utils/common'
import { faCheck, faFileArchive, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { BLUE, GREEN, RED } from '../../constants/colors'
import { tap } from '../../lib/promise'
import { SecondaryButton } from '../../components/actions/common'

type PropertyRow = {
  propertyId: string
  address: string
  success?: boolean
  skipped?: boolean
  error?: Error
}

type ResultCounts = {
  skipped: number
  success: number
  error: number
  total: number
}

type BulkResult = Record<string, Outcome<string, Error>>

const PropertyEditBulk: React.FC = () => {
  const location = useLocation()
  const [gridApi, setGridApi] = useState(null)
  const [error, setError] = useState<Error>()
  const [errorMsg, setErrorMsg] = useState('')
  const [properties, setProperties] = useState<PropertyRow[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [bulkDropdownOpen, setBulkDropdownOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFunctionKey, setSelectedFunctionKey] = useState<string | null>(null)
  const [bulkActions, setBulkActions] = useState<{ functionKey: string, functionName: string }[]>([])
  const [isWorking, setIsWorking] = useState(false)
  const [gridData, setGridData] = useState<PropertyRow[]>([])
  const [results, setResults] = useState<BulkResult>({})
  const [bulkActionId, setBulkActionId] = useState('')
  const [resultCounts, setResultCounts] = useState<ResultCounts>({
    skipped: undefined,
    success: undefined,
    error: undefined,
    total: undefined
  })

  const selectedPropertyIds = location.state?.selectedPropertyIds
  const gridRef = useRef(null)

  // refresh the properties in the grid; breaks it down into chunks of 200 if needed
  const refresh = async (propertyIds: string[]) => {
    const fetchProperties = async () => {
      try {
        for (let i = 0; i < propertyIds.length; i += 200) {
          const batch = propertyIds.slice(i, i + 200)
          const properties = await getPropertiesByIds(batch)
          setProperties(prevProperties => [...prevProperties, ...properties])
        }
      } catch (e) {
        setError(e)
        setErrorMsg(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (propertyIds && propertyIds.length > 0) {
      setProperties([])
      return fetchProperties()
    }
  }

  // load properties fed in from selectedPropertyIds
  useEffect(() => {
    refresh(selectedPropertyIds)
  }, [selectedPropertyIds])

  // load available bulk actions
  useEffect(() => {
    const fetchBulkActions = async () => {
      try {
        const actions = await getPropertyBulkActions()
        setBulkActions(actions)
      } catch (e) {
        setError(e)
        setErrorMsg(e.message)
        console.error('could not fetch bulk actions:', e)
      }
    }

    fetchBulkActions()
  }, [])

  // when results come back, merge them into the properties row for the grid display
  useEffect(() => {
    if (properties.length > 0) {
      const mergedGridData = properties.map(property => {
        const row = results[property.propertyId]

        if (!row)
          return property

        const success = isOk(row)
        const skipped = success && row.value === 'skipped'
        const error = !success && row.error

        return { ...property, success, skipped, error }
      })

      setGridData(mergedGridData)

      if (hasResults(results)) {
        const counts = {
          skipped: mergedGridData.filter(row => row.success && row.skipped).length,
          success: mergedGridData.filter(row => row.success && !row.skipped).length,
          error: mergedGridData.filter(row => !row.success).length
        }

        setResultCounts({
          ...counts,
          total: counts.skipped + counts.success + counts.error
        })
      }
    }
  }, [properties, results])

  const hasResults = (results: Record<string, unknown>) => Object.keys(results).length > 0

  // toggle the bulk actions selector
  const bulkDropdownToggle = () => setBulkDropdownOpen(!bulkDropdownOpen)

  // when a bulk action is selected, open the modal with that action form
  const onDropdownSelect = (fnKey: string) => {
    setSelectedFunctionKey(fnKey)
    setIsModalOpen(true)
  }

  // toggle the bulk action modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  // apply the bulk action to the selected properties
  const applyBulkAction = ({ functionKey, params }) => {
    const threshold = new Date().toISOString()

    setIsWorking(true)

    applyBulkPropertyAction({
      propertyIds: selectedPropertyIds,
      functionKey,
      params
    })
      .then(tap(async res => {

        // not all properties will be updated; only wait for properties that were updated
        const propertyIdsToWaitFor = Object.entries<Outcome<unknown, unknown>>(res.results)
          .filter(([, v]) => isOk(v) && v.value !== 'skipped')
          .map(([k]) => k)

        const wait = waitForIndexing({
          pollingInterval: 1000,
          maxRetries: 20,
          fetchIndexTimes: () => getPropertiesIndexTimes({ propertyIds: propertyIdsToWaitFor })
        })

        // waits (and polls) for the changes to show up in ES
        await wait({
          ids: propertyIdsToWaitFor,
          threshold
        })

        // refresh the grid with the new changes from ES
        await refresh(selectedPropertyIds)
      }))
      .then(res => {
        successToast('Bulk action applied to selected properties')
        setResults((res as any).results)
        setBulkActionId((res as any).bulkActionId)
      }).catch(e => {
        setError(e)
        setErrorMsg(e.message)
        errorToast('Could not apply bulk action')
      }).finally(() => {
        setIsWorking(false)
        toggleModal()
      })
  }

  const onGridReady = params => {
    setGridApi(params.api)
  }

  const exportToExcel = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        processGroupHeaderCallback: (params) => {
          // first group id in AgGrid is '2'
          return params.columnGroup.groupId === '2' ? bulkActionId : ''
        },
        processCellCallback: param =>
          param.column.getColDef().valueFormatter
            ? param.column.getColDef().valueFormatter({ value: param.value })
            : param.value
      })
    }
  }

  // renders a success with green checkmark, failure with red x
  const ResultCellRenderer = props => {
    const { node, data } = props
    const { success, skipped } = data

    if (!node)
      return null

    const onExpandClicked = () =>
      node.setExpanded(!node.expanded)

    return (
      <>
        {skipped ? (
          ResultIconSkipped
        ) : success ? (
          ResultIconSuccess
        ) : (
          <>
            <span
              onClick={onExpandClicked}
              className={`ag-icon ${node.expanded ? 'ag-icon-tree-open' : 'ag-icon-tree-closed'}`}
              style={{ marginRight: '5px', cursor: 'pointer' }}
            />
            {ResultIconError}
          </>
        )}
      </>
    )
  }

  const ResultErrorDetailRenderer = params => {
    const { error } = params.data
    return (
      <div className='p-3'>{error}</div>
    )
  }

  const pruneNonErrored = () => {
    const prunedGridRows = gridData.filter(row => !row.success)
    setGridData(prunedGridRows)
  }

  // column definitions for the grid; basic columns plus a results column if results are present
  const columnDefs = useMemo(() => {
    const baseColumns: (ColDef<any, any> | ColGroupDef<any>)[] = [
      { field: 'propertyId', headerName: 'Id', width: 300, cellRenderer: LinkRenderer, cellRendererParams: { prefix: '/admin/propertyedit' } },
      { field: 'address', headerName: 'Address', width: 300, cellRenderer: AddressLinkRenderer, cellRendererParams: { prefix: '/admin/propertyedit' }, filter: 'agTextColumnFilter' },
      {
        headerName: 'Valuation', children: [
          { field: 'detail.valuation.value', headerName: 'Value', cellRenderer: CurrencyRenderer, width: 120, filter: 'agNumberColumnFilter' },
          { field: 'detail.valuation.date', columnGroupShow: 'open', headerName: 'Date', valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
          { field: 'detail.valuation.low', columnGroupShow: 'open', headerName: 'Low', cellRenderer: CurrencyRenderer, filter: 'agNumberColumnFilter' },
          { field: 'detail.valuation.high', columnGroupShow: 'open', headerName: 'High', cellRenderer: CurrencyRenderer, filter: 'agNumberColumnFilter' },
        ],
      },
      { field: 'detail.owner_sell_price', headerName: 'Price to move', cellRenderer: CurrencyRenderer, filter: 'agNumberColumnFilter' },
      { field: 'detail.minimum_offer', headerName: 'Minimum Bluebid', cellRenderer: CurrencyRenderer, filter: 'agNumberColumnFilter' },
      { field: 'detail.bluebid_estimate.value', headerName: 'Bluebid Estimate', cellRenderer: CurrencyRenderer, filter: 'agNumberColumnFilter' },
      { field: 'detail.buyers_agent_commission', headerName: 'Buyer\'s Agent Fee', },
      {
        headerName: 'Created / Modified', children: [
          { field: 'createdAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
          { field: 'modifiedAt', columnGroupShow: 'open', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' }
        ]
      }
    ]

    if (hasResults(results)) {
      return [
        {
          headerName: 'Result',
          field: 'results',
          cellRenderer: ResultCellRenderer,
          width: 90,

          // export needs plain text results, no icon
          valueGetter: params => {
            return params.data
          },
          valueFormatter: params => {
            const { success, skipped, error } = params.value
            if (skipped) {
              return 'Skipped';
            } else if (success) {
              return 'Success';
            } else {
              return `Error: ${error}`
            }
          }
        },
        ...baseColumns
      ]
    }

    return baseColumns
  }, [results])

  return (<>
    <ErrorScreen error={error} message={errorMsg} />

    {!isLoading && properties.length > 0 && bulkActions.length > 0 &&
      <ErrorBoundary fallbackRender={({ error }) => <ErrorScreen error={error} message={error?.message} />}>
        <GridListHeader title={'Bulk Property Changes'} onRefresh={null}>
          {resultCounts.total > 0 && <HeaderActionIcon icon={faFileArchive} onClick={() => exportToExcel()} />}
        </GridListHeader>

        <ButtonDropdown isOpen={bulkDropdownOpen} toggle={bulkDropdownToggle}>
          <DropdownToggle caret>
            Select a Bulk Action
          </DropdownToggle>
          <DropdownMenu>
            {bulkActions.map(({ functionKey, functionName }) => (
              <DropdownItem key={functionKey} onClick={() => onDropdownSelect(functionKey)}>
                {functionName}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>

        <div style={{ height: '400px', width: '100%' }} className='ag-theme-balham mt-3'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            rowData={gridData}
            columnDefs={columnDefs}
            detailCellRenderer={ResultErrorDetailRenderer}
            masterDetail={true}
            detailRowAutoHeight={true}
          />
        </div>

        <Row>
          <Col md={4}>
            <Table size="sm" className="mt-3">
              <tbody>
                {resultCounts.total && (
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Results ({ bulkActionId })</th>
                  </tr>
                )}
                <tr>
                  <td width="25">{ResultIconSuccess}</td>
                  <td>Success</td>
                  {resultCounts.total && (<td>{resultCounts.success}/{resultCounts.total}</td>)}
                </tr>
                <tr>
                  <td>{ResultIconError}</td>
                  <td>Error</td>
                  {resultCounts.total && (<td>{resultCounts.error}</td>)}
                </tr>
                <tr>
                  <td>{ResultIconSkipped}</td>
                  <td>Skipped</td>
                  {resultCounts.total && (<td>{resultCounts.skipped}</td>)}
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col>
            {resultCounts.total && resultCounts.error !== resultCounts.total && (
              <>
                <div className="mt-3"><SecondaryButton onClick={() => pruneNonErrored()}>Remove Successful Results</SecondaryButton></div>
              </>
            )}
          </Col>
        </Row>
      </ErrorBoundary>
    }

    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <strong>Bulk Operation</strong> ({properties.length} properties)
      </ModalHeader>
      <ModalBody>
        {selectedFunctionKey && React.createElement(bulkEditForms[selectedFunctionKey], {
          onApply: (data: unknown) => {
            applyBulkAction({
              functionKey: selectedFunctionKey,
              params: data
            })
          },
          onCancel: () => {
            toggleModal()
          },
          isWorking
        })}
      </ModalBody>
    </Modal>
  </>)
}

export default withAuthenticationRequired(PropertyEditBulk, {
  onRedirecting: () => <Loading />,
})

const ResultIconSkipped = (<FontAwesomeIcon icon={faMinus} style={{ color: BLUE }} />)
const ResultIconSuccess = (<FontAwesomeIcon icon={faCheck} style={{ color: GREEN }} />)
const ResultIconError = (<FontAwesomeIcon icon={faTimes} style={{ color: RED }} />) 
