import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { cloneRevision } from '../../lib/data/templates'

import { PrimaryButton as NewPrimaryButton, SecondaryButton as NewSecondaryButton } from '@bluebid-sdk/react-components'

export const TemplateRevisionCloneModal: React.FC<{
  isOpen: boolean,
  templateKey: string,
  sourceRevisionId: string,
  onClose: () => void
}> = ({ isOpen, templateKey, sourceRevisionId, onClose }) => {
  const navigate = useNavigate()

  const [isCloning, setIsCloning] = useState(false)
  const [cloneLabel, setCloneLabel] = useState<string>('')

  const clone = () => {
    setIsCloning(true)
    cloneRevision({ revisionId: sourceRevisionId, label: cloneLabel })
      .then(rev => {
        setTimeout(() => {
          onClose()
          setIsCloning(false)
          navigate(`/admin/templates/${templateKey}/${rev.id}`)
        }, 2000)
      })
      .catch(() => {
        setIsCloning(false)
      })
  }

  return (
    <Modal size='md' isOpen={isOpen} backdrop='static'>
      <ModalHeader>Clone Revision</ModalHeader>
      <ModalBody>
        <div className="pb-2">Enter new revision label:</div>
        <Input
          type='text'
          value={cloneLabel}
          onChange={
            (e) => {
              setCloneLabel(e.target.value)
            }
          }
        />
      </ModalBody>
      <ModalFooter>
        <NewSecondaryButton color="secondary" onClick={() => onClose()}>Cancel</NewSecondaryButton>
        
        { sourceRevisionId && (
          <NewPrimaryButton color="primary" onClick={clone} disabled={isCloning}>
            { !isCloning && <>Clone</> }
            { isCloning && <>Cloning...</> }
          </NewPrimaryButton>
        )}
      </ModalFooter>
    </Modal>
  )
}