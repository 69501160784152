import React, { useCallback, useMemo, useRef } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'

import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import { DateValueFormatter, restoreGridState } from '../../components/grid/GridRenderers'
import { OfferHistoryCellRenderer } from './OfferHistoryCellRenderer'
import Loading from '../../components/Loading'
import { useSessionStorage } from '../../lib/utils'
import { address } from '../../utils/common'
import { GridListHeader, HeaderActionIcon } from './GridListHeader'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
  MasterDetailModule,
  MenuModule,
])

const ID_WIDTH = 280

// Show current offers
const currentOffersDS = new Datasource({
  apiCaller: api,
  index: 'offers',
  query: 'NOT _exists_:childId AND (status:Revoked OR status:Rejected or status:Accepted or status:Expired)',
  sort: '-modifiedAt',
  adminSearch: true,
})

const ThreadLinkRenderer = (params) => {
  if (!params?.data?.threadId && !params?.data?.recipientId) {
    return ''
  }
  return (
    <div>
      <Link to={`/admin/view-thread/${params.data.bidderId}/${params.data.threadId}`}>{address(params)}</Link>
    </div>
  )
}

const ListInactiveOffers = () => {
  const gridRef = useRef()

  // never changes, so we can use useMemo
  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])
  const detailCellRenderer = useMemo(() => {
    return OfferHistoryCellRenderer
  }, [])

  const [colState, setColState] = useSessionStorage('inactiveoffers', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel()
  }, [])

  const currentOffersColDefs = useMemo(() => [
    { field: 'id', headerName: 'Id', hide: true, width: ID_WIDTH },
    { field: 'modifiedAt', headerName: 'Last Activity Date', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
    { field: 'expiredAt', headerName: 'Expiry Date', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
    { field: 'threadId', headerName: 'Property Address', width: ID_WIDTH, filter: 'agTextColumnFilter', cellRenderer: ThreadLinkRenderer },
    // expand to offer history

    { field: 'status', headerName: 'Bluebid Status', width: 120, filter: 'agTextColumnFilter' },
    { field: 'note', headerName: 'Annotation', width: 120, filter: 'agTextColumnFilter' },
    // { field:'bidderId', headerName:'Bidder Id', width:ID_WIDTH, filter:'agTextColumnFilter', },
    { field: 'financing', headerName: 'Financing', width: 150, filter: 'agTextColumnFilter' },
    { field: 'contingencies', headerName: 'Contingencies', width: 200, filter: 'agTextColumnFilter' },
    // { field:'detail.owner', headerName:'Owner', width:ID_WIDTH, filter:'agTextColumnFilter', },
    {
      headerName: 'Created / Modified', children: [
        { field: 'createdAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
        { field: 'modifiedAt', columnGroupShow: 'open', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
      ],
    },
  ], [])

  return (<>
      <GridListHeader title={'Inactive Bluebids'} onRefresh={onBtnRefresh} >
          <HeaderActionIcon icon={faFileArchive} onClick={() => onBtExport()} />
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            masterDetail={true}
            defaultColDef={defaultColDef}
            columnDefs={currentOffersColDefs}
            detailCellRenderer={detailCellRenderer}
            detailCellRendererParams={{ offersInactive: true }}
            rowModelType='serverSide'
            serverSideDatasource={currentOffersDS}
            serverSideInfiniteScroll={true}
            enableRangeSelection='true'
            sideBar={sideBar}
            // debug={true}
          />
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(ListInactiveOffers, {
  onRedirecting: () => <Loading />,
})
