import React, { useMemo, useRef, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { AgGridReact } from '@ag-grid-community/react'
import { FormGroup, Input, Label, Modal, ModalHeader, ModalBody } from 'reactstrap'

import { ClaimDetail, ClaimError, ClaimErrorStatuses, Property, PropertyDetail } from '@bluebid-sdk/core'
import { Datasource } from '@bluebid-sdk/api-client'

import { api, updateClaimError } from '../../lib/api'
import { claimErrorsGridColumns, RowDetailRender } from './claim-errors-grid-columns.fn'
import { GridContainerComponent } from '../grid'
import { PropertyClaim } from '../properties'

export const ClaimErrorsGridComponent: React.FC<{
  title?: string
  sessionKey?: string
}> = ({ title = 'Claim Errors', sessionKey = 'claim-errors' }) => {
  const navigate = useNavigate()
  const gridRef = useRef<AgGridReact>()
  const [showDone, setShowDone] = useState(false)
  const [claimError, setClaimError] = useState<ClaimError>()
  const [claimDetail, setClaimDetail] = useState<Partial<ClaimDetail>>()
  const [fipsApnPropertyDetail, setFipsApnPropertyDetail] = useState<PropertyDetail>()
  const [claimModalIsOpen, setClaimModalIsOpen] = useState(false)

  const query = showDone ? undefined : `status.keyword:(NOT done)`

  const datasource = useMemo(
    () => new Datasource({ apiCaller: api, index: 'claimerrors', adminSearch: true, query, fields: 'user' }),
    [query]
  )

  const toggleClaimModal = ({
    claimError,
    claimDetail,
    fipsApnPropertyDetail,
  }: {
    claimError?: ClaimError
    claimDetail?: ClaimDetail
    fipsApnPropertyDetail?: PropertyDetail
  } = {}) => {
    setClaimModalIsOpen((prevState) => !prevState)
    setClaimError(claimError)
    setClaimDetail(claimDetail)
    setFipsApnPropertyDetail(fipsApnPropertyDetail)
  }
  const toggleShowDone = () => setShowDone(!showDone)
  const refresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const columnDefs = useMemo(() => claimErrorsGridColumns({ refresh, toggleClaimModal }), [])

  const SubHeader = () => (
    <div className="mb-3">
      <FormGroup switch className="float-start mt-2" style={{ minHeight: 'auto' }}>
        <Input type="switch" onChange={toggleShowDone} checked={showDone} />
        <Label check onClick={toggleShowDone}>
          Show "Done" Claim Errors
        </Label>
      </FormGroup>
      <div style={{ clear: 'both' }} />
    </div>
  )

  const onPropertyClaim = async (property: Property, claimError?: ClaimError) => {
    if (claimError?.id) {
      await updateClaimError(claimError.id, { status: ClaimErrorStatuses.Done })
    }

    navigate(`/admin/propertyedit/${property.propertyId}`)
  }

  const onClaimCancel = () => {
    setClaimModalIsOpen(false)
  }

  return (
    <>
      <GridContainerComponent
        title={title}
        subHeader={<SubHeader />}
        gridRef={gridRef}
        sessionKey={sessionKey}
        columnDefs={columnDefs}
        serverSideDatasource={datasource}
        masterDetail={true}
        detailCellRenderer={RowDetailRender}
        detailCellRendererParams={{ toggleClaimModal }}
        keepDetailRows={true}
      />
      <Modal size="xl" isOpen={claimModalIsOpen} toggle={() => toggleClaimModal()}>
        <ModalHeader toggle={() => toggleClaimModal()}>Claim Property</ModalHeader>
        <ModalBody>
          <PropertyClaim
            claimDetail={claimDetail}
            fipsApnPropertyDetail={fipsApnPropertyDetail}
            onPropertyClaim={(property) => onPropertyClaim(property, claimError)}
            onCancel={onClaimCancel}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
