import React, { useState } from 'react'
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { PropertyDetail } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { PrimaryButton as NewPrimaryButton, SecondaryButton as NewSecondaryButton } from '@bluebid-sdk/react-components'

import { api } from '../../lib/api'
import { successToast, errorToast } from '../../utils/common'

export const FipsApnClaimDialog: React.FC<{
  onSelect: (data: PropertyDetail) => void
  fips?: string
  apn?: string
  buttonLabel?: string
  buttonProps?: React.HTMLAttributes<typeof NewSecondaryButton>
}> = ({ onSelect, fips: initialFips, apn: initialApn, buttonLabel = 'FIPS / APN', buttonProps = {} }) => {
  const [modal, setModal] = useState(false)
  const [processing, setProcessing] = useState(false)
  const toggle = () => setModal(!modal)
  const onAction = (data: PropertyDetail) => {
    toggle()
    onSelect(data)
  }

  const [fips, setFips] = useState<string>(initialFips)
  const [apn, setApn] = useState<string>(initialApn)

  const btnDisabled = !fips || !apn

  const submit = async () => {
    if (fips && apn) {
      setProcessing(true)

      const response = await api(`/properties/detail/fipsApn?fips=${fips}&apn=${apn}`)

      if (isSuccessResponse(response)) {
        const data = response.data

        onAction(data)

        successToast('Found Property!')
      } else {
        errorToast(response.errorDetail?.message || response.errorMessage)
      }

      setProcessing(false)
    }
  }

  return (
    <>
      <NewSecondaryButton {...buttonProps} onClick={toggle}>
        <FontAwesomeIcon icon={faSearchLocation} /> {buttonLabel}
      </NewSecondaryButton>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Get Property Data with FIPS/APN</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FieldLabel>FIPS</FieldLabel>
              <input
                className={'form-control'}
                value={fips}
                onChange={(e) => setFips(e.target.value)}
                type="text"
                name="fips"
                id="fips"
                placeholder={'FIPS'}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FieldLabel>APN</FieldLabel>
              <input
                className={'form-control'}
                value={apn}
                onChange={(e) => setApn(e.target.value)}
                type="text"
                name="apn"
                id="apn"
                placeholder={'APN'}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col>
              <NewPrimaryButton disabled={processing || btnDisabled} onClick={submit}>
                Submit
              </NewPrimaryButton>
            </Col>
            <Col className="text-right">
              <NewSecondaryButton disabled={processing} onClick={toggle}>
                Cancel
              </NewSecondaryButton>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

const FieldLabel = styled(Label)`
  font-weight: bold;
  margin-top: 20px;
  font-size: 1rem;
`
