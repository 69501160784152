import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import Select from 'react-select'
import styled from 'styled-components'
import { faSearchLocation, faUser, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import deepmerge from 'deepmerge'

import {
  Property,
  PropertyDetail,
  PropertyDetailAddressModel,
  PropertyDetailStructure,
  ClaimDetail,
  User,
} from '@bluebid-sdk/core'
import { isErrorResponse } from '@bluebid-sdk/api-client'
import { SecondaryButton as NewSecondaryButton, BlockUI, VPad } from '@bluebid-sdk/react-components'

import { claimWithDetail } from '../../lib'
import { PROPERTY_CLASSIFICATION } from '../../lib/data/data'
import { getUser } from '../../lib/data/bluebidData'
import { submitClaimedPropertyInquiry, userToContactSheet } from '../../lib/inquiries'
import { FipsApnClaimDialog } from './fips-apn-claim-dialog.component'
import { MapAddressBrowser } from '../MapAddressBrowser'
import { UserSearch } from '../UserSearch'
import { EditDetails, SelectStyles } from '../EditDetails'
import { SecondaryButton } from '../actions/common'
import { GetDataPopup } from '../../views/admin/CreateClaim'
import { successToast } from '../../utils/common'
import { BluebidBlue } from '../../constants/colors'
import { ClipboardCopy } from '../adminActions/CopyClipboard'

const EditAddressPopup: React.FC<{
  onSelect: (data: { latitude: number; longitude: number; address?: string }) => void
}> = ({ onSelect }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const onAction = (data: { latitude: number; longitude: number; address?: string }) => {
    toggle()
    onSelect(data)
  }

  return (
    <>
      <ActionButton onClick={toggle}>
        <FontAwesomeIcon icon={faSearchLocation} /> Location
      </ActionButton>
      <Modal isOpen={modal} toggle={toggle} size={'xl'}>
        <ModalBody>
          <MapAddressBrowser
            onUseAddress={onAction}
            onCancel={toggle}
            location={undefined}
            locationOnly={undefined}
            initialAddress={undefined}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

const EditDetailsPopup: React.FC<{ onSelect: (detail: PropertyDetail) => void; detail: PropertyDetail }> = ({
  onSelect,
  detail,
}) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const onUpdateDetail = (detail: PropertyDetail) => {
    toggle()
    onSelect(detail)
  }

  return (
    <>
      <ActionButton onClick={toggle}>
        <FontAwesomeIcon icon={faUsersCog} /> Edit Facts
      </ActionButton>
      <Modal isOpen={modal} toggle={toggle} size={'xl'}>
        <ModalBody>
          <EditDetails detail={detail} onCancel={toggle} onUpdate={onUpdateDetail} expandAdvanced={false} />
        </ModalBody>
      </Modal>
    </>
  )
}

const EditUserPopup: React.FC<{ onSelect: (detail: { value: string; label: string; data: User }) => void }> = ({
  onSelect,
}) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const onAction = (detail: { value: string; label: string; data: User }) => {
    toggle()
    onSelect(detail)
  }

  return (
    <>
      <ActionButton onClick={toggle}>
        <FontAwesomeIcon icon={faUser} /> Owner
      </ActionButton>
      <Modal isOpen={modal} toggle={toggle} size={'lg'}>
        <ModalHeader>Assign Owner</ModalHeader>
        <ModalBody>
          <UserSearch onSelect={onAction} label="" />
        </ModalBody>
        <ModalFooter>
          <SecondaryButton className={'btn-primary btn-margin float-right mr-4'} onClick={() => toggle()}>
            Cancel
          </SecondaryButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

const ActionButton: React.FC<React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>> = ({
  children,
  ...props
}) => {
  return (
    <NewSecondaryButton width={140} altcolor={BluebidBlue} {...props}>
      {children}
    </NewSecondaryButton>
  )
}

const ErrorMessage = ({ error }) => {
  if (!error) {
    return <></>
  }
  return <ErrorText>{error}</ErrorText>
}

export const PropertyClaim: React.FC<{
  onPropertyClaim: (property: Property) => void
  onCancel?: () => void
  claimDetail?: Partial<ClaimDetail>
  fipsApnPropertyDetail?: PropertyDetail
}> = ({ onPropertyClaim, onCancel, claimDetail: initialClaimDetail = {}, fipsApnPropertyDetail }) => {
  const [sendInquiry, setSendInquiry] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [selectedPropertyClassification, setSelectedPropertyClassification] = useState<{
    label: string
    value: string
  }>()
  // TODO: verify data fields before allowing claim action
  const [canClaim] = useState(true)
  const [claimDetail, setClaimDetail] = useState<Partial<ClaimDetail>>(initialClaimDetail)

  useEffect(() => {
    if (fipsApnPropertyDetail) {
      const dataAddress = fipsApnPropertyDetail.address as PropertyDetailAddressModel
      const address = `${dataAddress.formatted_street_address} ${dataAddress.city}, ${dataAddress.state} ${dataAddress.zip_code}`
      const latitude = dataAddress.latitude
      const longitude = dataAddress.longitude

      updateAddress(address)
      updateLocation({ latitude, longitude, address })
      onGetData(fipsApnPropertyDetail, true)
    }
  }, [fipsApnPropertyDetail])

  // update location in claim info
  const updateLocation = (location: { latitude: number; longitude: number; address?: string }) => {
    setClaimDetail((prevState) => ({
      ...prevState,
      latitude: location.latitude,
      longitude: location.longitude,
    }))

    if (location.address) {
      setClaimDetail((prevState) => ({
        ...prevState,
        address: location.address,
      }))
    }
  }

  const useFipsApn = (data: PropertyDetail) => {
    const dataAddress = data.address as PropertyDetailAddressModel
    const address = `${dataAddress.formatted_street_address} ${dataAddress.city}, ${dataAddress.state} ${dataAddress.zip_code}`
    const latitude = dataAddress.latitude
    const longitude = dataAddress.longitude

    updateAddress(address)
    updateLocation({ latitude, longitude, address })
    onGetData(data, true)
  }

  const updateDetailField = <K extends keyof PropertyDetail, V extends PropertyDetail[K]>(fieldName: K, value: V) => {
    const newDetail = claimDetail?.detail || ({} as PropertyDetail)

    newDetail[fieldName] = value

    setClaimDetail((prevState) => ({
      ...prevState,
      detail: newDetail,
    }))

    if (fieldName === 'property_classification') {
      const propertyClassification = PROPERTY_CLASSIFICATION.find((x) => x.value === value)

      setSelectedPropertyClassification(propertyClassification)
    }
  }

  const updateDetailStructureField = <K extends keyof PropertyDetailStructure, V extends PropertyDetailStructure[K]>(
    fieldName: K,
    value: V
  ) => {
    const newStructure = claimDetail?.detail?.structure || ({} as PropertyDetailStructure)

    newStructure[fieldName] = value

    setClaimDetail((prevState) => ({
      ...prevState,
      detail: {
        ...claimDetail?.detail,
        structure: newStructure,
      },
    }))
  }

  const updateAddress = (address: string) => {
    setClaimDetail((prevState) => ({
      ...prevState,
      address,
    }))
  }

  const updateEmail = (email?: string) => {
    setClaimDetail((prevState) => ({
      ...prevState,
      email: email || '',
    }))
  }

  const updateCreateUser = (createUser: boolean) => {
    setClaimDetail((prevState) => ({
      ...prevState,
      createUser,
    }))
  }

  const onGetData = (data: PropertyDetail, overwrite: boolean) => {
    // console.log(data)
    // merge data with claimDetail
    // overwrite or keep
    if (!overwrite) {
      // merge exiting onto data
      deepmerge(data, claimDetail?.detail)
    }

    let newDetail = data

    // console.log(newDetail)
    setClaimDetail((prevState) => ({
      ...prevState,
      detail: newDetail,
    }))
  }

  const clearData = () => {
    setClaimDetail((prevState) => ({
      email: prevState?.email,
      address: undefined,
      latitude: undefined,
      longitude: undefined,
      detail: undefined,
      sendNotification: prevState?.sendNotification,
    }))
    setSendInquiry(false)
    setError(undefined)
  }

  const claimProperty = async () => {
    // Claim the property
    setLoading(true)
    const response = await claimWithDetail(claimDetail as ClaimDetail)
    setLoading(false)
    if (isErrorResponse(response)) {
      // if request is too large it's most likely a HUGE boundary. remove WKT prop, reduce coords and try again
      if (response.statusCode === 413 && claimDetail?.detail?.boundary?.wkt) {
        setClaimDetail((prevState) => {
          const newState = { ...prevState }

          delete newState.detail.boundary.wkt

          if (newState.detail.boundary.geojson?.coordinates?.[0]?.[0]?.length > 500) {
            newState.detail.boundary.geojson.coordinates[0][0] =
              newState.detail.boundary.geojson.coordinates[0][0].slice(0, 500)
          }

          return newState
        })

        setTimeout(() => claimProperty(), 300)

        return
      }
      // error
      let errMsg = response.errorMessage
      setError(errMsg)
    } else {
      successToast('Property has been claimed')

      if (sendInquiry) {
        const user = await getUser(response.data.ownerId)

        await submitClaimedPropertyInquiry({
          propertyId: response.data.propertyId,
          address: response.data.address,
          contactSheet: userToContactSheet(user),
        })
      }

      clearData()

      onPropertyClaim(response.data)
    }
  }

  const toggleNotify = () => {
    setClaimDetail((prevState) => ({
      ...prevState,
      sendNotification: !prevState?.sendNotification,
    }))
  }

  const toggleSendInquiry = () => {
    setSendInquiry(!sendInquiry)
  }

  const onUpdateDetail = (detail: PropertyDetail) => {
    setClaimDetail((prevState) => ({
      ...prevState,
      detail: detail,
    }))

    if (detail?.property_classification) {
      const propertyClassification = PROPERTY_CLASSIFICATION.find((x) => x.value === detail.property_classification)

      setSelectedPropertyClassification(propertyClassification)
    }
  }

  return (
    <div>
      <BlockUI blocking={loading}></BlockUI>
      {/* Address */}
      <Row>
        <Col md={2} className={'offset-md-1'}>
          <VPad height={20}></VPad>
          <EditAddressPopup onSelect={updateLocation} />
          <VPad height={10}></VPad>
          <FipsApnClaimDialog buttonProps={{ width: 140, altcolor: BluebidBlue } as any} onSelect={useFipsApn} />
        </Col>

        <Col md={8}>
          <FieldLabel>Address</FieldLabel>
          <input
            className={'form-control'}
            value={claimDetail?.address}
            onChange={(e) => updateAddress(e.target.value)}
            type="text"
            name="address"
            id="address"
            placeholder={'Address'}
          />
          <ClipboardCopy copyText={claimDetail?.address}>Copy</ClipboardCopy>
        </Col>
      </Row>

      {/* Lat /lng */}
      <Row>
        <Col md={4} className={'offset-md-3'}>
          <FieldLabel>Latitude</FieldLabel>
          <input
            className={'form-control'}
            type="number"
            name="latitude"
            id="latitude"
            value={claimDetail?.latitude}
            readOnly={true}
            placeholder={'latitude'}
          />
        </Col>
        <Col md={4}>
          <FieldLabel>Longitude</FieldLabel>
          <input
            className={'form-control'}
            type="number"
            name="longitude"
            id="longitude"
            value={claimDetail?.longitude}
            readOnly={true}
            placeholder={'longitude'}
          />
        </Col>
      </Row>

      {/* User */}
      <Row>
        <Col md={2} className={'offset-md-1'}>
          <VPad height={20}></VPad>
          {!claimDetail?.createUser && <EditUserPopup onSelect={(user) => updateEmail(user?.data?.email)} />}
        </Col>
        <Col md={8}>
          <FieldLabel>Owner</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="email"
            id="email"
            value={claimDetail?.email}
            onChange={(e) => updateEmail(e.target.value)}
            readOnly={!claimDetail?.createUser}
            placeholder={'email'}
          />
          <FormGroup className="mt-1" check>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => {
                  updateCreateUser(!claimDetail?.createUser)
                  updateEmail('')
                }}
                checked={claimDetail?.createUser}
              />{' '}
              Create User?
            </Label>
          </FormGroup>
        </Col>
      </Row>

      {/* Detail */}
      <Row>
        <Col md={2} className={'offset-md-1'}>
          <VPad height={40}></VPad>
          <SideLabel>Basic Facts</SideLabel>
        </Col>
        <Col md={2}>
          <FieldLabel>Sq Footage</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="total_area_sq_ft"
            id="total_area_sq_ft"
            value={claimDetail?.detail?.structure?.total_area_sq_ft}
            onChange={(e) =>
              updateDetailStructureField('total_area_sq_ft', e.target.value ? +e.target.value : undefined)
            }
            placeholder={'Total Sq. Feet'}
          />
        </Col>
        <Col md={2}>
          <FieldLabel>Beds</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="beds_count"
            id="beds_count"
            value={claimDetail?.detail?.structure?.beds_count}
            onChange={(e) => updateDetailStructureField('beds_count', e.target.value ? +e.target.value : undefined)}
            placeholder={'# Beds'}
          />
        </Col>
        <Col md={2}>
          <FieldLabel>Baths</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="baths"
            id="baths"
            value={claimDetail?.detail?.structure?.baths}
            onChange={(e) => updateDetailStructureField('baths', e.target.value ? +e.target.value : undefined)}
            placeholder={'# Baths'}
          />
        </Col>
        <Col md={2}>
          <FieldLabel>Partial Baths</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="partial_baths_count"
            id="partial_baths_count"
            value={claimDetail?.detail?.structure?.partial_baths_count}
            onChange={(e) =>
              updateDetailStructureField('partial_baths_count', e.target.value ? +e.target.value : undefined)
            }
            placeholder={'# Partial Baths'}
          />
        </Col>
      </Row>

      <Row>
        <Col md={2} className={'offset-md-1'}></Col>

        <Col md={2}>
          <FieldLabel>Year Built</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="year_built"
            id="year_built"
            value={claimDetail?.detail?.structure?.year_built}
            onChange={(e) => updateDetailStructureField('year_built', e.target.value ? +e.target.value : undefined)}
            placeholder={'Year Built'}
          />
        </Col>
        <Col md={2}>
          <FieldLabel>Fireplaces</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="fireplaces"
            id="fireplaces"
            value={claimDetail?.detail?.structure?.fireplaces}
            onChange={(e) => updateDetailStructureField('fireplaces', e.target.value ? +e.target.value : undefined)}
            placeholder={'# Fireplaces'}
          />
        </Col>
        <Col md={2}>
          <FieldLabel>Parking Spaces</FieldLabel>
          <Input
            className={'form-control'}
            type="text"
            name="parking_spaces_count"
            id="parking_spaces_count"
            value={claimDetail?.detail?.structure?.parking_spaces_count}
            onChange={(e) =>
              updateDetailStructureField('parking_spaces_count', e.target.value ? +e.target.value : undefined)
            }
            placeholder={'# Parking Spaces'}
          />
        </Col>
        <Col md={2}>
          <VPad height={20}></VPad>
          <Label for="parkingSpace">Property Classification</Label>
          <Select
            styles={SelectStyles}
            value={selectedPropertyClassification}
            onChange={(v) => updateDetailField('property_classification', v.value)}
            options={PROPERTY_CLASSIFICATION}
          />
        </Col>
      </Row>

      <Row>
        <Col md={2} className={'offset-md-3'}>
          <VPad height={20}></VPad>
          <GetDataPopup
            address={claimDetail?.address}
            onSelect={onGetData}
            enabled={!!claimDetail?.address}
            iconOnly={undefined}
            checkOnly={undefined}
          />
        </Col>
        <Col md={2}>
          <VPad height={20}></VPad>
          <EditDetailsPopup detail={claimDetail?.detail} onSelect={onUpdateDetail} />
        </Col>
      </Row>

      <Row>
        <VPad height={20}></VPad>
        <Col md={8} className={'offset-md-2'}>
          <ErrorMessage error={error} />
        </Col>
      </Row>

      <Row>
        <VPad height={20}></VPad>
        <Col className={'offset-md-8'}>
          <FormGroup switch>
            <Input type="switch" onChange={toggleNotify} checked={claimDetail?.sendNotification} />
            <Label check onClick={toggleNotify}>
              Send claim email notification
            </Label>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <VPad height={20}></VPad>
        <Col className={'offset-md-8'}>
          <FormGroup switch>
            <Input type="switch" onChange={toggleSendInquiry} checked={sendInquiry} />
            <Label check onClick={toggleSendInquiry}>
              Send inquiry to agent
            </Label>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <VPad height={20}></VPad>
        <Col className={'offset-md-8'}>
          <ActionButton className="me-2" onClick={() => onCancel?.()}>
            Cancel
          </ActionButton>
          <ActionButton disabled={!canClaim} onClick={() => claimProperty()}>
            Create Claim
          </ActionButton>
        </Col>
      </Row>
    </div>
  )
}

const FieldLabel = styled(Label)`
  font-weight: bold;
  margin-top: 20px;
  font-size: 1rem;
`

const SideLabel = styled.div`
  font-size: 1.2rem;
`

const ErrorText = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 22pt;
  color: red;
`
