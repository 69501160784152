import { useEffect, useState } from 'react'

import { Property, User } from '@bluebid-sdk/core'
import { getUser } from '@bluebid-sdk/api-client'

import { useApiContext } from '../../../contexts'
import { getClaimedPropertyInfo } from '../../../lib/data/bluebidData'
import { GUID } from '../../../lib/utils'

export const useProperty = (propertyId: string) => {
  const { apiClient } = useApiContext()
  const [property, setProperty] = useState<Property>()
  const [owner, setOwner] = useState<User>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)

  const refetchProperty = () => {
    setLoading(true)
    getClaimedPropertyInfo(propertyId)
      .then((p) => {
        if (p.status === 'error') {
          setError(p.errorDetail)
        } else {
          // console.log('property', p)
          setProperty(p)
          apiClient.callFn(getUser, { userId: p.ownerId }).then((response) => {
            // console.log('owner', response.data)
            setOwner(response.data)
          })
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    // if param is a guid it's a claimed propertyId
    if (!GUID.test(propertyId)) {
      setError(`Bad/invalid propertyId ${propertyId}`)
    } else {
      refetchProperty()
    }
  }, [propertyId])

  return {
    property,
    owner,
    error,
    loading,
    refetchProperty,
  }
}
