import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import styled from 'styled-components'

import { Agent, AgentContent, AGENT_CONTENT_FIELDS } from '@bluebid-sdk/core'
import { getAgentById, isSuccessResponse, isErrorResponse } from '@bluebid-sdk/api-client'
import { PrimaryButton as NewPrimaryButton, BlockUI } from '@bluebid-sdk/react-components'

import { useApiContext } from '../contexts'
import { updateAgent } from '../lib'
import { uploadAsset } from '../lib/api'
import { ClipboardCopy } from './adminActions/CopyClipboard'
import { getEnv } from '../config'

export const EditAgentContent: React.FC<{
  userId: string
  onUserError?: (errorMessage: string) => void
  onSaveSuccess?: () => void
  onSaveError?: (errorMessage: string) => void
}> = ({ userId, onUserError, onSaveSuccess, onSaveError }) => {
  const { apiClient } = useApiContext()
  const [agent, setAgent] = useState<Agent>()
  const [agentUrl, setAgentUrl] = useState<string>()
  const [agentContent, setAgentContent] = useState<AgentContent>()
  const [agentImgFile, setAgentImgFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(true)

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target?.files?.[0]

    setAgentImgFile(file)
  }

  const handleOnSave = async () => {
    if (userId && (agentContent || agentUrl)) {
      setIsLoading(true)

      let response
      if (agentContent) {
        let mainImageUrl = agentContent.mainImageUrl

        if (agentImgFile) {
          const uploadResponse = await uploadAsset(agentImgFile, `agent_${userId}`)

          if (isErrorResponse(uploadResponse)) {
            onSaveError?.(uploadResponse.errorMessage || 'Error')
          } else {
            mainImageUrl = uploadResponse.data?.url || ''
          }
        }

        const updatedAgentContent: AgentContent = { ...agentContent, mainImageUrl }

        setAgentContent(updatedAgentContent)

        response = await updateAgent(userId, { agentUrl: agentUrl || '', content: updatedAgentContent })
      } else {
        response = await updateAgent(userId, { agentUrl: agentUrl || '', content: undefined })
      }

      if (isSuccessResponse(response)) {
        onSaveSuccess?.()
      } else {
        onSaveError?.(response.errorMessage || 'Error')
      }

      setAgentImgFile(undefined)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (userId) {
      ;(async () => {
        const response = await apiClient.callFn(getAgentById, { id: userId })

        if (isSuccessResponse(response)) {
          setAgent(response.data)
          setAgentUrl(response.data?.agentUrl)
          setAgentContent(response.data?.content)
          setIsLoading(false)
        } else {
          onUserError?.(response.errorMessage || 'Error')
        }
      })()
    }
  }, [userId])

  const inviteUrl = agentUrl ? `${getEnv().appHost}/invite/${agentUrl}` : null

  return (
    <React.Fragment>
      <BlockUI blocking={isLoading} />

      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <AgentLabel for={'agentUrl'}>Agent URL</AgentLabel>
                <AgentInput
                  type="text"
                  name="agentUrl"
                  onChange={(e) => setAgentUrl(e.target.value)}
                  value={agentUrl}
                  placeholder="Enter Agent URL"
                />
              </Col>
              <Col>
                <AgentLabel for={'agentUrl'}>Invite URL</AgentLabel>
                <ClipboardCopy copyText={inviteUrl}>{inviteUrl}</ClipboardCopy>
              </Col>
            </Row>
            <br />

            <Row>
              {/*@todo: move these into distinct fields to get better layout control*/}
              {AGENT_CONTENT_FIELDS.map((agentContentField) => (
                <Col key={`${agentContentField.key}`} xs={6} sm={agentContentField.wide ? 4 : 4}>
                  <FormGroup>
                    <AgentLabel for={agentContentField.key}>{agentContentField.label}</AgentLabel>
                    {['input', 'textarea'].includes(agentContentField.type) && (
                      <AgentInput
                        type={agentContentField.type === 'textarea' ? 'textarea' : 'text'}
                        name={agentContentField.key}
                        onChange={(e) =>
                          setAgentContent({
                            ...agentContent,
                            [agentContentField.key]: e.target.value,
                          } as AgentContent)
                        }
                        value={agentContent?.[agentContentField.key]}
                        placeholder={`Enter ${agentContentField.label}`}
                        style={agentContentField.type === 'textarea' ? { height: '250px' } : undefined}
                      />
                    )}
                    {['image'].includes(agentContentField.type) && (
                      <React.Fragment>
                        {!agentImgFile && !!agentContent?.[agentContentField.key] && (
                          <img
                            style={{ width: '250px' }}
                            className="d-block img-thumbnail mb-3"
                            src={agentContent[agentContentField.key]}
                          />
                        )}
                        <Input type="file" name={agentContentField.key} onChange={handleFileChange} />
                      </React.Fragment>
                    )}
                  </FormGroup>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Row>
      <NewPrimaryButton className="mt-2" width={100} height={38} onClick={handleOnSave}>
        Save
      </NewPrimaryButton>
    </React.Fragment>
  )
}

const AgentLabel = styled(Label)`
  font-size: 10pt;
  font-weight: bold;
`
const AgentInput = styled(Input)`
  font-size: 10pt;
`
