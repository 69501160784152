import React, { useCallback, useMemo, useRef } from 'react'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import { AgeValueFormatter, DateValueFormatter, restoreGridState } from '../../components/grid/GridRenderers'
import { AgGridReact } from '@ag-grid-community/react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons'

import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { OfferHistoryCellRenderer } from './OfferHistoryCellRenderer'
import Loading from '../../components/Loading'
import { useSessionStorage } from '../../lib/utils'
import { GridListHeader, HeaderActionIcon } from './GridListHeader'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
  MasterDetailModule,
  MenuModule,
  ClipboardModule,
])

const ID_WIDTH = 280

// Show current offers
const datasource = new Datasource({
  apiCaller: api,
  index: 'inplay',
  sort: '-createdAt',
  adminSearch: true,
})

const ListActiveOffers = () => {
  const gridRef = useRef()

  // never changes, so we can use useMemo
  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])
  const detailCellRenderer = useMemo(() => {
    return OfferHistoryCellRenderer
  }, [])

  const [colState, setColState] = useSessionStorage('inplay', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel()
  }, [])
  //needed for serverSide data model
  const getRowId = useCallback((params) => params.data.id, [])

  const currentOffersColDefs = useMemo(() => [
    { field: 'id', headerName: 'Id', hide: true, width: ID_WIDTH },
    { field: 'modifiedAt', headerName: 'Date', columnGroupShow: 'open', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
    { field: 'createdAt', headerName: 'Age', width: 80, valueFormatter: AgeValueFormatter, sort: 'desc' },
    { field: 'detail.address', headerName: 'Property', width: ID_WIDTH, filter: 'agTextColumnFilter', cellRenderer: 'agGroupCellRenderer' },
    { field: 'lastAction', headerName: 'Last Action', width: 140, filter: 'agTextColumnFilter' },
    { field: 'detail.owner', headerName: 'Owner', width: ID_WIDTH, filter: 'agTextColumnFilter' },
    { field: 'detail.bidder', headerName: 'Buyer', width: ID_WIDTH, filter: 'agTextColumnFilter' },
    { field: 'offerId', headerName: 'Bluebid Id', hide: true, width: ID_WIDTH },
    { field: 'ownerId', headerName: 'Owner Id', hide: true, width: ID_WIDTH },
    { field: 'propertyId', headerName: 'Property Id', hide: true, width: ID_WIDTH },

  ], [])

  return (<>
      <GridListHeader title={'Bluebids'} onRefresh={onBtnRefresh}>
        <HeaderActionIcon icon={faFileArchive} onClick={() => onBtExport()} />
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            masterDetail={true}
            defaultColDef={defaultColDef}
            columnDefs={currentOffersColDefs}
            detailCellRenderer={detailCellRenderer}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            getRowId={getRowId}
            serverSideInfiniteScroll={true}
            enableRangeSelection='true'
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(ListActiveOffers, {
  onRedirecting: () => <Loading />,
})
