import axios, { AxiosRequestConfig } from 'axios'

import { ApiResponse, createSuccessResponse, createErrorResponse } from '@bluebid-sdk/api-client'

export async function s3Upload(
  url: string,
  file: File,
  contentType?: string,
  onProgress?: (p: { total: number; progress: number }) => void
): Promise<ApiResponse<{ url: string }>> {
  const axiosResponse = await axios.request({
    method: 'PUT',
    url,
    data: file,
    headers: contentType ? { 'Content-Type': contentType } : undefined,
    onUploadProgress: (p) =>
      onProgress?.({
        total: p.total || 0,
        progress: p.total ? Math.round((p.loaded * 100) / p.total) : 0,
      }),
  } as AxiosRequestConfig)

  if (axiosResponse.status !== 200) {
    return createErrorResponse(axiosResponse.status, 'error uploading file to S3', axiosResponse)
  }

  const urlParts = (axiosResponse?.config?.url || '')
    .replace('s3.amazonaws.com/', '') // aws v2 handling (deprecated)
    .replace(/s3.[\w-]+.amazonaws.com\//, '') // aws v3 now includes region
    .replace(`dev.`, 'dev')
    .replace(`stage.`, 'stage')
    .replace(`prod.`, '')
    .split('?')

  return createSuccessResponse({
    url: urlParts[0],
  })
}
