import React, { useCallback, useMemo, useRef } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ModuleRegistry } from '@ag-grid-community/core'

import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { DateValueFormatter, restoreGridState } from '../../components/grid/GridRenderers'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useSessionStorage } from '../../lib/utils'
import { GridListHeader } from './GridListHeader'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])

const AddressStyle = styled.div`
  color: ${(props) => (props.isExclude ? 'red' : '')};
  color: ${(props) => (props.isAlias ? 'blue' : '')};
  text-decoration: ${(props) => (props.isExclude ? 'line-through' : '')};
`

const AddressRenderer = (params) => {
  return (
    <AddressStyle isAlias={params?.data?.aliasForId || false} isExclude={params?.data?.exclude || false}>
      {params.value}
    </AddressStyle>
  )
}

// Maps a filter value from true/false to the ES query expression.
// true - field must be set to 'true'
// false - field either set to 'false' or is empty
const booleanFieldFilterMapper = (fld, filter) => {
  if (filter.type === 'true') {
    return `${fld}:${filter.type === 'true'}`
  }
  return `${fld}:(${filter.type === 'true'} OR NOT _exists_:${fld})`
}

// boolean pull-down filter options
const BooleanFilterOptions = {
  filterOptions: [
    'empty',
    {
      displayKey: 'true',
      displayName: 'True',
      test: function(filterValue, cellValue) {
        return cellValue === 'true'
      },
      hideFilterInput: true,
    }, {
      displayKey: 'false',
      displayName: 'False',
      test: function(filterValue, cellValue) {
        return cellValue === 'false'
      },
      hideFilterInput: true,
    },
  ],
  suppressAndOrCondition: true,
}

const ExcludeRenderer = (params) => {
  if (params?.data?.exclude) {
    return <FontAwesomeIcon icon={faCheck} />
  }
  return <></>
}

const datasource = new Datasource({ apiCaller: api, index: 'addresses', adminSearch: true })
datasource.addTypeMapper('text', 'exclude', booleanFieldFilterMapper)

const ListAddresses = () => {
  const gridRef = useRef()

  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const [colState, setColState] = useSessionStorage('addresses', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])
  //needed for serverSide data model
  const getRowId = useCallback((params) => params.data.id, [])

  const columnDefs = useMemo(() => [
    { field: 'id', hide: true, width: 300, headerName: 'Address Id' },
    { field: 'address', width: 350, filter: 'agTextColumnFilter', cellRenderer: AddressRenderer, cellRendererParams: {} },
    { field: 'exclude', width: 95, filter: 'agTextColumnFilter', cellRenderer: ExcludeRenderer, cellRendererParams: {}, filterParams: BooleanFilterOptions, sortable: true },
    { field: 'aliasFor', headerName: 'Master Address', width: 350, filter: 'agTextColumnFilter' },
    {
      headerName: 'Location', children: [
        { field: 'location.lat', headerName: 'Latitude', width: 150, sortable: false },
        { field: 'location.lon', headerName: 'Longitude', width: 150, sortable: false },
      ],
    },
    {
      headerName: 'Created / Modified', children: [
        { field: 'createdAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
        { field: 'modifiedAt', columnGroupShow: 'open', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
      ],
    },
  ], [])

  return (<>
      <GridListHeader title={'Addresses'} onRefresh={onBtnRefresh} />

      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection='true'
            rowSelection='multiple'
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            serverSideInfiniteScroll={true}
            getRowId={getRowId}
            suppressRowClickSelection='true'
            cacheBlockSize={20}
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(ListAddresses, {
  onRedirecting: () => <Loading />,
})
