import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { License } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'

import { uploadAsset } from '../../lib/api'

export const ChangeBrandingPhotoModal: React.FC<{
  isOpen: boolean
  license: License
  toggleModal: () => void
  onUploadSuccess: (res) => void
  onUploadError: (err) => void
}> = ({ isOpen, toggleModal, license, onUploadSuccess, onUploadError }) => {
  const [selectedImage, setSelectedImage] = useState<File>()

  const handleFileChange = (event) => {
    const file = event.target.files[0]

    setSelectedImage(file)
  }

  const handleUpload = async () => {
    const uploadResponse = await uploadAsset(selectedImage, `branding_${license.groupId}`)

    if (isSuccessResponse(uploadResponse)) {
      setSelectedImage(undefined)
      toggleModal()
      onUploadSuccess(uploadResponse.data)
    } else {
      onUploadError(uploadResponse.errorMessage)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Branding Photo</ModalHeader>
      <ModalBody>
        <input type="file" onChange={handleFileChange} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
        <Button color="primary" disabled={!selectedImage} onClick={handleUpload}>
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  )
}
