import React, { useCallback, useMemo, useRef } from 'react'
import { Container } from 'reactstrap'
import { ModuleRegistry } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import styled from 'styled-components'

import { UserActions } from '@bluebid-sdk/core'
import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import {
  DateValueFormatter,
  restoreGridState,
  ResultFieldLinkRenderer,
  ResultFieldRenderer,
} from '../grid/GridRenderers'
import { GridListHeader } from '../../views/admin/GridListHeader'
import { GridContainerStyle, GridStyle, StandardSideBar } from '../CommonGrid'
import { HomeFactsModified } from './userActionDetails/HomeFactsModified'
import { HomeClickedOn } from './userActionDetails/HomeClickedOn'
import { HomeViewed } from './userActionDetails/HomeViewed'
import { AddressViewed } from './userActionDetails/AddressViewed'
import { AddressClickedOn } from './userActionDetails/AddressClickedOn'
import { LowBidPlaced } from './userActionDetails/LowBidPlaced'
import { ContactRequested } from './userActionDetails/ContactRequested'
import { CitySearchedFor } from './userActionDetails/CitySearchedFor'
import { HomeFavorited } from './userActionDetails/HomeFavorited'
import { HomeSearchedFor } from './userActionDetails/HomeSearchedFor'
import { CMARequested } from './userActionDetails/CMARequested'
import { OffMarketBuyer } from './userActionDetails/OffMarketBuyer'
import { OffMarketSeller } from './userActionDetails/OffMarketSeller'
import { ClaimStarted } from './userActionDetails/ClaimStarted'
import { OtpPrompted } from './userActionDetails/OtpPrompted'
import { ClaimCompleted } from './userActionDetails/ClaimCompleted'
import { UserLogin } from './userActionDetails/UserLogin'
import { CountySearchedFor } from './userActionDetails/CountySearchedFor'
import { ZipcodeSearchedFor } from './userActionDetails/ZipcodeSearchedFor'
import { useSessionStorage } from '../../lib/utils'

ModuleRegistry.registerModules([ClientSideRowModelModule])

/**
 * Converts an action key e.g. "HomeFactsModified" to a human readable
 * name e.g. "Home Facts Modified"
 */
const actionKeyToName = (actionKey) =>
  actionKey
    .replace(/([A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|$))/g, ' $1')
    .trim()
    .replace(/^./, (match) => match.toUpperCase())

// map of action keys to the components that show their details
const actionDetailComponentMap = {
  HomeFactsModified: HomeFactsModified,
  HomeClickedOn: HomeClickedOn,
  HomeViewed: HomeViewed,
  AddressViewed: AddressViewed,
  AddressClickedOn: AddressClickedOn,
  LowBidPlaced: LowBidPlaced,
  ContactRequested: ContactRequested,
  CitySearchedFor: CitySearchedFor,
  HomeFavorited: HomeFavorited,
  CMARequested: CMARequested,
  OffMarketBuyer: OffMarketBuyer,
  OffMarketSeller: OffMarketSeller,
  HomeSearchedFor: HomeSearchedFor,
  ClaimStarted: ClaimStarted,
  OtpPrompted: OtpPrompted,
  ClaimCompleted: ClaimCompleted,
  UserLogin: UserLogin,
  CountySearchedFor: CountySearchedFor,
  [UserActions.ZipcodeSearchedFor]: ZipcodeSearchedFor,
}

// all available actions
const actions = Object.keys(actionDetailComponentMap)
  .sort()
  .map((key) => ({
    name: actionKeyToName(key),
    action: key,
  }))

const UserActionsListDetailRenderer = (params) => {
  const userAction = params.data
  const Component = actionDetailComponentMap[userAction.action]
  return <Container className="py-3">{Component ? Component(userAction) : null}</Container>
}

const IpAddressRenderer = (params) => {
  if (!params.value) return <Greyed>Anonymous</Greyed>
  if (params.value === '::1') return <Greyed>Developer</Greyed>

  return params.value
}
const Greyed = styled.p`
  color: lightgrey;
`
export const UserActionsList = ({ userId, title }) => {
  const gridRef = useRef()

  const datasource = new Datasource({
    apiCaller: api,
    index: 'userActions',
    sort: '-createdAt',
    userId,
    userIdField: 'userId',
    adminSearch: true,
    fields: 'user',
  })

  const [colState] = useSessionStorage('useractions', '')
  // const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])

  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])
  const sideBar = useMemo(() => StandardSideBar, [])

  const commonColumns = [
    {
      headerName: 'Subject',
      width: 350,
      filter: 'agTextColumnFilter',
      cellRenderer: 'agGroupCellRenderer',
      valueGetter: (params) => {
        if (params.data.action === 'CitySearchedFor') {
          return `${params.data.data.city}, ${params.data.data.state}`
        }

        if (params.data.action === 'UserLogin') {
          const firstName = params.data.data.firstName
          const lastName = params.data.data.lastName
          const sub = params.data.data.sub

          const hasNoName = (!firstName && !lastName) || (firstName === '' && lastName === '')
          const isUserPassSignup = sub && sub.split('|')[0] === 'auth0'

          if (hasNoName && isUserPassSignup) {
            return 'No Name (Username/Password Signup)'
          }

          return `${firstName || ''} ${lastName || ''}`
        }

        if (params.data.action === 'CountySearchedFor') {
          return `${params.data.data.county}, ${params.data.data.state}`
        }

        if (params.data.action === UserActions.ZipcodeSearchedFor) {
          return params.data.data.zipcode
        }

        return params.data.data.address
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      valueFormatter: (params) => actionKeyToName(params.value),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: actions.map((a) => a.action),
      },
    },
    {
      field: 'ipAddr',
      headerName: 'IP Address',
      width: 200,
      filter: 'agTextColumnFilter',
      cellRenderer: IpAddressRenderer,
    },
    {
      field: 'createdAt',
      headerName: 'Timestamp',
      width: 210,
      valueFormatter: DateValueFormatter,
      filter: 'agDateColumnFilter',
      sort: 'desc',
    },

    {
      field: 'email',
      headerName: 'eMail',
      sortable: false,
      width: 140,
      cellRenderer: ResultFieldLinkRenderer,
      cellRendererParams: {
        prefix: '/admin/userprofile',
        object: 'user',
        field: 'email',
        linkField: 'userId',
      },
    },

    {
      field: 'first',
      headerName: 'First',
      sortable: false,
      width: 140,
      cellRenderer: ResultFieldRenderer,
      cellRendererParams: { object: 'user', field: 'firstName' },
    },

    {
      field: 'last',
      headerName: 'Last',
      sortable: false,
      width: 140,
      cellRenderer: ResultFieldRenderer,
      cellRendererParams: { object: 'user', field: 'lastName' },
    },

    { hide: true, field: 'id', headerName: 'UserAction Id', width: 300, filter: 'agTextColumnFilter' },
    { hide: true, field: 'userId', headerName: 'User Id', width: 300, filter: 'agTextColumnFilter' },
  ]

  return (
    <div style={{ ...(!!title ? containerStyle : gridStyle) }}>
      {!!title && <GridListHeader title={title} />}
      <div style={{ ...gridStyle }} className="ag-theme-balham">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          animateRows={true}
          masterDetail={true}
          columnDefs={commonColumns as any}
          detailCellRenderer={UserActionsListDetailRenderer}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          sideBar={sideBar}
          detailRowAutoHeight={true}
        />
      </div>
    </div>
  )
}
