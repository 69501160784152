import React, { useContext, useCallback, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { AgGridReact } from '@ag-grid-community/react'
import { faFileArchive, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BlockUI } from '@bluebid-sdk/react-components'
import { Datasource } from '@bluebid-sdk/api-client'

import { AuthContext } from '../../constants/context'
import { getApiHost } from '../../config'
import { downloadExport } from '../../lib/data/exporting'
import { api } from '../../lib/api'
import Loading from '../../components/Loading'
import { GroupRowDetailRender } from '../../components/grid'
import {
  DateValueFormatter,
  GroupLinkRenderer,
  LinkRenderer,
  restoreGridState,
} from '../../components/grid/GridRenderers'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import { CreateTeamAction } from '../../components/adminActions/CreateTeamAction'
import { useSessionStorage } from '../../lib/utils'
import { GridListHeader, HeaderActionIcon } from './GridListHeader'

const datasource = new Datasource({
  apiCaller: api,
  index: 'groups',
  sort: '-createdAt',
  adminSearch: true,
  query: 'NOT deleted:true'
})

export const InquiriesListRenderer = (params) => (
  <Link to={`/admin/list-license-inquiries/${params.data?.id}`}>
    <FontAwesomeIcon icon={faSearch} />
  </Link>
)

const ListGroups: React.FC = () => {
  const { getToken } = useContext(AuthContext)
  const gridRef = useRef<AgGridReact>()

  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const [isWorking] = useState(false)

  const [colState, setColState] = useSessionStorage('groups', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])
  //needed for serverSide data model
  const getRowId = useCallback((params) => params.data.id, [])

  const columnDefs = useMemo(
    () => [
      { field: 'id', width: 300, headerName: 'Group Id', cellRenderer: 'agGroupCellRenderer' },
      {
        field: 'name',
        headerName: 'License Name',
        width: 350,
        filter: 'agTextColumnFilter',
        cellRenderer: GroupLinkRenderer,
        cellRendererParams: { prefix: '/admin/group' },
      },
      {
        field: 'inquiries',
        headerName: 'Inquiries',
        width: 100,
        cellRenderer: InquiriesListRenderer
      },
      {
        field: 'ownerId',
        headerName: 'Owner ID',
        width: 350,
        filter: 'agTextColumnFilter',
        cellRenderer: LinkRenderer,
        cellRendererParams: { prefix: `/admin/userprofile` },
      },
      {
        headerName: 'Created / Modified',
        children: [
          {
            field: 'createdAt',
            width: 210,
            valueFormatter: DateValueFormatter,
            filter: 'agDateColumnFilter',
            sort: 'desc',
          },
          {
            field: 'modifiedAt',
            columnGroupShow: 'open',
            width: 210,
            valueFormatter: DateValueFormatter,
            filter: 'agDateColumnFilter',
          },
        ],
      },
    ],
    []
  )

  const onBtnExport = async () => {
    const exportUrl = `${getApiHost()}/licenses/export?sort=-createdAt`

    try {
      downloadExport({
        getToken,
        exportUrl,
      })
    } catch (error) {
      console.error('error downloading file:', error)
    }
  }

  return (
    <>
      <BlockUI blocking={isWorking} />

      <GridListHeader title={'Licenses'} onRefresh={onBtnRefresh}>
        <CreateTeamAction onComplete={onBtnRefresh} />
        <HeaderActionIcon icon={faFileArchive} onClick={() => onBtnExport()} />
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            masterDetail={true}
            animateRows={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            detailCellRenderer={GroupRowDetailRender}
            detailRowHeight={350}
            keepDetailRows={true}
            enableRangeSelection={true}
            rowSelection="multiple"
            rowModelType="serverSide"
            serverSideDatasource={datasource}
            getRowId={getRowId}
            cacheBlockSize={20}
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(ListGroups, {
  onRedirecting: () => <Loading />,
})
