import React, { useCallback, useMemo, useRef, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ModuleRegistry } from '@ag-grid-community/core'

import { formatPhoneNumber } from '@bluebid-sdk/core'
import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { CityLinkRenderer, GroupsCitiesGroupLinkRenderer, restoreGridState } from '../../components/grid/GridRenderers'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import { useSessionStorage } from '../../lib/utils'
import BlockUI from '../../components/BlockUI'
import { StateCitySearch } from './../../components/StateCitySearch'
import { errorToast } from '../../utils/common'
import { InitialState } from '../../constants/constants'
import { GridListHeader } from './GridListHeader'
import styled from 'styled-components'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])

const datasource = new Datasource({
  apiCaller: api,
  index: 'groupsCities',
  adminSearch: true,
})

/**
 * @deprecated use ListZipLicenses
 */
const ListCityLicenses = () => {
  const navigate = useNavigate()

  const gridRef = useRef()

  const [gotoState, setGotoState] = useState(InitialState)
  const [gotoCity, setGotoCity] = useState(undefined)

  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const [isWorking] = useState(false)
  const [colState, setColState] = useSessionStorage('cityLicenses', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const columnDefs = useMemo(() => [
    { field: 'state', headerName: 'State', width: 100, filter: 'agTextColumnFilter' },
    { field: 'city', headerName: 'City', width: 160, filter: 'agTextColumnFilter', cellRenderer: CityLinkRenderer },
    { field: 'groupId', headerName: 'Group ID', width: 300, filter: 'agTextColumnFilter', cellRenderer: GroupsCitiesGroupLinkRenderer, cellRendererParams: { prefix: `/admin/group` } },
    {
      headerName: 'Branding', children: [
        { field: 'teamRep.label', headerName: 'Label', width: 165, filter: 'agTextColumnFilter' },
        { field: 'teamRep.phone', headerName: 'Phone', width: 110, filter: 'agTextColumnFilter', valueFormatter: (params) => formatPhoneNumber(params.value) },
        { field: 'teamRep.email', headerName: 'Email', width: 175, filter: 'agTextColumnFilter' },
      ],
    },
  ], [])

  const handleGoto = () => {
    if (!gotoState || !gotoCity) {
      errorToast('Please select a state and city')
      return
    }

    const cityDetailUrl = `/admin/city/${gotoState.toLowerCase()}/${gotoCity.toLowerCase()}`
    navigate(cityDetailUrl)
  }

  return (<>
      <BlockUI blocking={isWorking} />

      <GridListHeader title={'City Licenses'} onRefresh={onBtnRefresh}>
        <Container>
          <StateCitySearch onSelectState={setGotoState} onSelectCity={setGotoCity} initialState={InitialState} />

          <Button color='primary' type='submit' onClick={handleGoto}>
            Go {gotoState && gotoCity && ` to ${gotoCity}, ${gotoState}`}
          </Button>
        </Container>
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection='true'
            rowSelection='multiple'
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            serverSideInfiniteScroll={true}
            suppressRowClickSelection='true'
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

const Container = styled.div`
  width: 600px;
  display: flex;
  margin: 0 auto;
`

/**
 * @deprecated use ListZipLicenses
 */
export default withAuthenticationRequired(ListCityLicenses, {
  onRedirecting: () => <Loading />,
})
