import React, { createContext, useContext, useMemo } from 'react'

import { ApiClient } from '@bluebid-sdk/api-client'

import { api } from '../lib'

type ApiContextType = {
  apiClient?: ApiClient
}

const ApiContext = createContext<ApiContextType>({})

export const ApiProvider = ({ children }) => {
  const apiClient = useMemo(() => new ApiClient(api), [])

  const contextValue: ApiContextType = {
    apiClient,
  }

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
}

export const useApiContext = () => useContext(ApiContext)
