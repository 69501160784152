import styled, { css } from 'styled-components'

import { SecondaryButton as NewSecondaryButton } from '@bluebid-sdk/react-components'

export const AdminSecondaryActionButton = styled(NewSecondaryButton)`
  font-family: "Roboto", sans-serif;
  min-width: 160px;
  margin-bottom: 4px;
  font-size: 11pt;
  text-align: left;

  color: #166cc5;

  &.btn-warning {
    font-weight: bold;
  }

  &.btn-danger {
    color: white;
    font-weight: bold;
  }

  :not(.btn-warning):not(.btn-danger) {
    background-color: white;
    &:hover {
      background-color: #adadae;
    }
  }
`

export const AdminActionButton = styled(NewSecondaryButton)`
  min-width: 200px;
`
export const AdminWarningActionButton = styled(AdminActionButton)`
  border: 1px solid red;
  color: red;
`

export const AdminToggleActionButton = styled(AdminActionButton)`
  border: 1px solid red;
  color: red;

  ${(props) =>
    props.type === 'secondary'
      ? css`
          color: #027ffe;
          border-color: #027ffe;
        `
      : ''}
`
