import { Property, ClaimDetail } from '@bluebid-sdk/core'

import { api } from '../api.fn'

export function claimWithDetail(options: ClaimDetail) {
  return api<Property>('/properties/usingDetail', {
    method: 'POST',
    body: options,
  })
}
