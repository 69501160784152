import { License } from '@bluebid-sdk/core'

import { api } from '../api.fn'

export function createLicense(groupId: string, zipcode: string) {
  return api<License>('/licenses', {
    method: 'POST',
    body: {
      groupId,
      zipcodes: [zipcode],
    },
  })  
}
