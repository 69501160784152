import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ModuleRegistry } from '@ag-grid-community/core'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import styled from 'styled-components'

import { formatPhoneNumber } from '@bluebid-sdk/core'
import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { SUBSCRIBER_TYPES } from '../../lib/data/data'
import { DateValueFormatter, GeoValueGetter, IconRenderer, LinkRenderer, restoreGridState, SubscriberRenderer } from '../../components/grid/GridRenderers'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import { UserSearch } from '../../components/UserSearch'
import { getApiHost } from '../../config'
import { PropertySearchWidget } from './PropertySearchWidget'
import { useNavigate } from 'react-router-dom'
import { useSessionStorage } from '../../lib/utils'
import { GridListHeader, HeaderActionIcon } from './GridListHeader'
import { AuthContext } from '../../constants/context'
import { AgentEditCellEditor } from '../../components/grid/AgentEditCellEditor'
import { downloadExport } from '../../lib/data/exporting'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
  MenuModule,
])

const datasource = new Datasource({ apiCaller: api, index: 'users', adminSearch: true })

const ListUsers = () => {
  const gridRef = useRef<AgGridReact>()
  const { getToken } = useContext(AuthContext)

  // never changes, so we can use useMemo
  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])
  const navigate = useNavigate()
  const [colState, setColState] = useSessionStorage('users', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const selectProperty = (sel) => navigate(`/admin/propertyedit/${sel.value}`)
  const selectUser = (sel) => navigate(`/admin/userprofile/${sel.value}`)

  const onBtExport = useCallback(() => {
    try {
      downloadExport({
        getToken,
        exportUrl: `${getApiHost()}/users/export?${datasource.exportQueryParams}`
      })
    } catch (error) {
      console.error('error downloading file:', error)
    }
  }, [])

  //needed for serverSide data model
  const getRowId = useCallback((params) => params.data.id, [])

  const columnDefs = useMemo(() => [
    { field: 'id', headerName: 'User Id', filter: 'agTextColumnFilter', hide: true },
    { field: 'email', headerName: 'Email', width: 250, cellRenderer: LinkRenderer, cellRendererParams: { prefix: '/admin/userprofile', field: 'id' }, filter: 'agTextColumnFilter' },
    { field: 'firstName', headerName: 'First', width: 100, filter: 'agTextColumnFilter', valueFormatter: params => params.value?.toUpperCase() },
    { field: 'lastName', headerName: 'Last', width: 100, filter: 'agTextColumnFilter', valueFormatter: params => params.value?.toUpperCase() },
    // { field: 'emailVerified', headerName: 'Verified', width: 80, filter: 'agSetColumnFilter', filterParams: { values: [true, false] } },
    { field: 'phone', headerName: 'Phone', width: 120, filter: 'agTextColumnFilter', valueFormatter: (params) => formatPhoneNumber(params.value) },
    { field: 'picture', headerName: 'Icon', width: 50, cellRenderer: IconRenderer, hide: true },
    { field: 'lastLoginAt', headerName: 'Last Login', width: 180, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
    { field: 'agentUrl', headerName: 'Agent URL', width: 120, cellRenderer: AgentEditCellEditor, hide: true },
    {
      headerName: 'Created / Modified', children: [
        { field: 'createdAt', width: 180, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
        { field: 'modifiedAt', columnGroupShow: 'open', width: 180, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
      ],
    },
    { field: 'sub', headerName: 'Source', width: 80, cellRenderer: SubscriberRenderer, filter: 'agSetColumnFilter', filterParams: { values: SUBSCRIBER_TYPES } },
    {
      headerName: 'Geo Info', children: [
        { field: 'geoip.cityName', width: 150, filter: 'agTextColumnFilter' },
        { field: 'geoip.countryCode', columnGroupShow: 'open', width: 80, filter: 'agTextColumnFilter' },
        { field: 'geoip.countryName', columnGroupShow: 'open', width: 200, filter: 'agTextColumnFilter' },
        { headerName: 'Location', field: 'geoip', columnGroupShow: 'open', width: 150, filter: false, valueGetter: GeoValueGetter },
      ],
    }

  ], [])

  return (<>
      <GridListHeader title={'Users'} onRefresh={onBtnRefresh}>
        <Container>
          <UserSearch onSelect={selectUser} label={'Find claims for user'} />

          <PropertySearchWidget onSelect={selectProperty} />

          <HeaderActionIcon icon={faFileArchive} onClick={() => onBtExport()} />
        </Container>
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection={true}
            rowSelection='multiple'
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            getRowId={getRowId}
            suppressRowClickSelection={true}
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

const Container = styled.div`
  margin-left: 20px;
  display: flex;
`

export default withAuthenticationRequired(ListUsers, {
  onRedirecting: () => <Loading />,
})
