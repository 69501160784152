import React, { useEffect, useState } from 'react'

import { PropertyInvite } from '@bluebid-sdk/core'
import { getOwnerInvite, isSuccessResponse } from '@bluebid-sdk/api-client'

import { useApiContext } from '../../../contexts'

export const useAgentReferral = (property) => {
  const { apiClient } = useApiContext()
  const [propertyInvite, setPropertyInvite] = useState<PropertyInvite>()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (property?.propertyId && property?.ownerId) {
      setLoading(true)
      apiClient
        .callFn(getOwnerInvite, { propertyId: property.propertyId, ownerId: property.ownerId })
        .then((response) => {
          if (isSuccessResponse(response)) {
            setPropertyInvite(response.data)
          }
        })
        .catch(setError)
        .finally(() => setLoading(false))
    }
  }, [property?.propertyId, property?.ownerId])

  return {
    propertyInvite,
    error,
    loading,
  }
}
