import { Agent } from '@bluebid-sdk/core'

import { api } from '../api.fn'

export function updateAgent(id: string, agent: Agent) {
  return api(`/users/id/${id}/agent`, {
    method: 'POST',
    body: agent,
  })
}
