import heic2any from 'heic2any'

export const onImageDrop = (droppedFiles: File[]) => {
  return Promise.all(droppedFiles.map(async (file) => {
    let preview: string

    if (file.type === 'image/heic') {
      const result = await heic2any({ blob: file, toType: 'image/jpeg' })
      preview = URL.createObjectURL(result as Blob)
    } else {
      preview = URL.createObjectURL(file)
    }

    return Object.assign(file, { preview })
  }))
}