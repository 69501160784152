import { ClaimErrorUpdateOptions } from '@bluebid-sdk/core'

import { api } from '../api.fn'

export async function updateClaimError(claimErrorId: string, options: ClaimErrorUpdateOptions) {
  return api(`/claimErrors/${claimErrorId}`, {
    method: 'put',
    body: options,
  })
}
