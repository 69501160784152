import { MlsStatuses, Property } from '@bluebid-sdk/core'
import { ApiFn } from '@bluebid-sdk/api-client'

export const updateMlsStatus: ApiFn<
  Property,
  { propertyId: string; mlsStatus: MlsStatuses; userEdited?: boolean }
> = async (apiCaller, params) => {
  return apiCaller(`/properties/${params?.propertyId}/updateMlsStatus`, {
    method: 'PUT',
    body: {
      mlsStatus: params?.mlsStatus,
      userEdited: params?.userEdited,
    },
  })
}
