import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'

import { AdminSearchTypes, Invite } from '@bluebid-sdk/core'
import { Datasource } from '@bluebid-sdk/api-client'

import { api } from '../../lib/api'
import { AdminSearch } from '../Search'
import apiCall from '../../lib/data/apiCall'
import { GridStyle } from '../../components/CommonGrid'
import { GridListHeader } from '../../views/admin/GridListHeader'
import { GridComponent } from '../grid'
import { invitesGridColumns } from './invites-grid-columns.fn'

export const InvitesGridComponent: React.FC<{
  title?: string
  enableSearch?: boolean
  senderEmail?: string
  overrideColumnDefs?: (ColDef | ColGroupDef)[]
  sessionKey?: string
}> = ({ title = 'Invites', enableSearch = true, senderEmail = '', overrideColumnDefs, sessionKey = 'invites' }) => {
  const gridRef = useRef<AgGridReact>()

  const gridStyle = useMemo(() => GridStyle, [])

  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const [emailFilter, setEmailFilter] = useState(senderEmail)
  const [query, setQuery] = useState<string>()

  const datasource = useMemo(
    () => new Datasource({ apiCaller: api, index: 'invites', sort: '-createdAt', adminSearch: true, query }),
    [query]
  )

  useEffect(() => {
    setQuery(emailFilter ? `sender:"${emailFilter}"` : undefined)
  }, [emailFilter])

  const getContainerHeight = () => {
    let spacing = 0

    if (title && enableSearch) {
      spacing = 143.6
    } else if (title && !enableSearch) {
      spacing = 81.6
    } else if (!title && enableSearch) {
      spacing = 78
    }

    return `calc(100% - ${spacing}px)`
  }

  const handlePatchFlag = (flag) => (id, value) => {
    apiCall(`/invites/flag/${id}`, {
      method: 'PATCH',
      body: { [flag]: value },
    }).finally(() =>
      setTimeout(() => {
        gridRef.current.api.refreshInfiniteCache()
      }, 500)
    )
  }

  const columnDefs = useMemo(
    () =>
      invitesGridColumns({
        onToggleDelete: handlePatchFlag('isDeleted'),
        onToggleAdmin: handlePatchFlag('isAdmin'),
      }),
    []
  )

  return (
    <>
      {!!title && <GridListHeader title={title} onRefresh={onBtnRefresh} />}

      {!!enableSearch && (
        <Row className="mb-4">
          <Col xs={3}>
            <AdminSearch<Invite>
              searchType={AdminSearchTypes.Invites}
              searchTransformFn={(search) => `sender.keyword:${search}*`}
              searchResultsFilterFn={(searchResults) => {
                const senderEmails = []
                const filteredSearchResults = {
                  ...searchResults,
                  items: [],
                }

                searchResults?.items?.forEach((item) => {
                  if (item?.sender && !senderEmails.includes(item.sender.toLowerCase())) {
                    senderEmails.push(item.sender.toLowerCase())
                    filteredSearchResults.items.push(item)
                  }
                })

                return filteredSearchResults
              }}
              renderSearchResult={(searchResult) => <>{searchResult.sender}</>}
              onSearchResultSelect={(searchResult) => {
                setEmailFilter(searchResult?.sender)
              }}
              placeholder="Search sender email"
              enableAdhoc
              onAdhoc={(value) => {
                setEmailFilter(value)
              }}
            />
          </Col>
        </Row>
      )}

      <div style={{ width: '100%', height: getContainerHeight() }}>
        <div style={gridStyle} className="ag-theme-balham">
          <GridComponent
            gridRef={gridRef}
            sessionKey={sessionKey}
            columnDefs={overrideColumnDefs || columnDefs}
            enableRangeSelection={true}
            rowSelection="multiple"
            serverSideDatasource={datasource}
            suppressRowClickSelection={true}
            cacheBlockSize={20}
          />
        </div>
      </div>
    </>
  )
}
