import { Branding } from '@bluebid-sdk/core'

import { api } from '../api.fn'

export function saveBranding(licenseId: string, branding: Branding) {
  return api(`/licenses/${licenseId}/branding`, {
    method: 'PUT',
    body: branding,
  })
}
