import {useState} from "react";
import {propertySearch} from "../lib/data/bluebidData";
import styled from "styled-components";
import OutsideClickHandler from "./OutsideClickHandler";
import { Black } from '../constants/colors'
import Chance from 'chance'

let chance = new Chance()

/**
 * NOTE - use of session token:
 * Autocomplete sends partial queries to the server, which returns address suggestions.
 * Each query invocation calls the server which combines data from Bluebid and Google AutoComplete
 *
 * On the initial query a session token is created
 * Each subsequent query maintains state by passing that token
 * When a search suggestion is 'accepted' the token is finally passed to Google Places search
 * Google ties together all the AutoComplete and final Places search into a single transaction,
 *    which substantially reduces the charges for the API calls.
 *
 * @param onSelect
 * @returns {JSX.Element}
 * @constructor
 */
const AutoComplete = ({onSelect}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [value, setValue] = useState('');
    const [session, setSession] = useState()
    // const navigate = useNavigate();

    // search term has changed
    const handleChange = (e) => {
        const query = e.target.value.toLowerCase();
        setValue(query);

        if (query.length > 1) {
            let token = session
            if (!token) {
                token = chance.guid()
                setSession(token)
            }
            propertySearch(token, query)
                .then(results => {
                    let matches = results.matches.filter(r =>
                        r.description !== '33 Bellevue Road, Wellesley, MA' && r.description !== '33 Bellevue Rd, Wellesley, MA')
                    // console.log(results.matches)
                    // setSuggestions(results.matches)
                    setSuggestions(matches)
                })
        }
    };

    // clear all search suggestions
    const clearInput = () => {
        setSuggestions([])
        setSuggestionIndex(0);
        setValue('')
    }

    const valueAt = (ndx) => {
        return suggestions.length > 0 ? suggestions[ndx].description : ''
    }

    const onFocus = () => clearInput()

    // user clicked on an item in the list
    // selected - what user clicked on
    // entered - what user entered into search box
    const handleClick = (selected, entered) => {
        setSuggestions([]);
        setSession(undefined)
        setValue(selected)
        let item = suggestions.find(s => s.description === selected)
        onSelect(session, item)
    };

    // perform select action on the currently highlited suggestion
    const selectCurrentItem = () => {
        setValue(valueAt(suggestionIndex));
        setSuggestionIndex(0);
        if (suggestions.length > 0) {
            onSelect(session, suggestions[suggestionIndex])
            setSuggestions([])
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 27) { // ESC
            clearInput()
        }
        if (e.keyCode === 38) { // UP ARROW
            if (suggestionIndex === 0) {
                return;
            }
            setSuggestionIndex(suggestionIndex - 1);
        }
        else if (e.keyCode === 40) { // DOWN ARROW
            if (suggestionIndex - 1 === suggestions.length) {
                return;
            }
            setSuggestionIndex(suggestionIndex + 1);
        }
        else if (e.keyCode === 13) { // ENTER
            selectCurrentItem()
        }
    };

    const boldMatchedCharacters = (sentence = '') => {
        const regEx = new RegExp(value, 'gi');
        return sentence.replace(regEx, '<strong>$&</strong>')
    }

    const SuggestionsListComponent = () => {
        return suggestions?.length ? (
            <Suggestions>
                {suggestions.map((suggestion, index) => {
                    return (
                        <SuggestItem
                            className={index === suggestionIndex ? "suggestion-active" : ""}
                            key={suggestion.place_id}
                            onClick={(e) => handleClick(suggestion.description, e.target.innerText)} >
                            <SuggestText dangerouslySetInnerHTML={{__html: boldMatchedCharacters(suggestion.description)}}></SuggestText>
                        </SuggestItem>
                    );
                })}
            </Suggestions>
        ) : '';
    };

    // const navToHome = () => navigate('/')

    return (
        <OutsideClickHandler onOutsideClick={() => clearInput()}>
            <AutoCompleteContainer>
                <AutoCompleteSearchBar>
                    <AutoCompleteInput
                        type="text"
                        id="searchQueryInput"
                        value={value}
                        onFocus={onFocus}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Search by address"
                        // onClick={navToHome}
                    />

                    <AutoCompleteSubmit type="submit" name="searchQuerySubmit" onClick={() => selectCurrentItem()}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="24"
                             height="24"
                             viewBox="0 0 24 24">
                            <path fill="#fff"
                                  d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"/>
                        </svg>
                    </AutoCompleteSubmit>
                </AutoCompleteSearchBar>
                <SuggestionsListComponent />
            </AutoCompleteContainer>
        </OutsideClickHandler>
    )
}


const AutoCompleteContainer = styled.div.attrs({
    className: 'auto-complete-container'
})`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  
`;

const AutoCompleteSearchBar = styled.div.attrs({
    className: 'auto-complete-searchbar'
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`

const AutoCompleteInput = styled.input.attrs({
    classname: 'auto-complete-input'
})`
  width: 100%;
  height: 2.5rem;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 0.325rem;
  padding: 0 40px 0 10px;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  max-width: 800px;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7F7F7F;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #7F7F7F;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #7F7F7F;
  }

  @media (max-width: 768px) {
    width: 98%;
    font-size: 0.75rem;
    font-weight: 600;
  }
  @media (max-width: 590px) {
    font-size: 0.85rem;
    font-weight: 600;
  }
  @media (max-width: 416px) {
    font-size: 0.70rem;
    font-weight: 600;
  }
  @media (max-width: 320px) {
    font-size: 0.55rem;
    font-weight: 600;
  }
`
const AutoCompleteSubmit = styled.div`
    margin-left: -2.5rem;
    background: none;
    border: none;
    outline: none;
    background-color: #007fff;
    padding: 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-appearance: initial;
    :hover {
        cursor: pointer;
    }

  //@media (max-width: 416px) {
  //  display: none;
  //}
`
const Suggestions = styled.ul`
  border: 1px solid #dddcdc;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 90vh;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  min-width: 300px;
  position: absolute;
  z-index: 1061;
  background-color: white;
  border-bottom-left-radius: 0.325rem;
  border-bottom-right-radius: 0.325rem;  
  
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #007fff;
  }
`
const SuggestItem = styled.li`
  padding: 12px 0 12px 0;
  font-size: 10pt;
  margin: 0;

  &:hover, &.suggestion-active {
    background: linear-gradient(
            90deg,
            hsla(220, 4%, 85%, 0.6) 0%,
            hsla(0, 0%, 98%, 1) 200%
    );
    cursor: pointer;
    color: ${Black};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #f6f4f4;
  }
`
const SuggestText = styled.ul`
    margin: 0;
    padding: 0 12px;
    color: ${Black};
`

export default AutoComplete;
