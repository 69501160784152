import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { faServer } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Property, PropertyDetail } from '@bluebid-sdk/core'
import { SecondaryButton as NewSecondaryButton } from '@bluebid-sdk/react-components'

import { PropertyClaim } from '../../components'
import { ATOMDataBrowser } from '../../components/ATOMDataBrowser'
import { IconActionButton } from '../../components/actions/common'
import Loading from '../../components/Loading'
import { BluebidBlue } from '../../constants/colors'

const CreateClaim: React.FC = () => {
  const navigate = useNavigate()

  const onPropertyClaim = (property: Property) => {
    navigate(`/admin/propertyedit/${property.propertyId}`)
  }

  const onCancel = () => {
    navigate('/')
  }

  return (
    <Container className="py-4">
      <h4>Claim Information</h4>

      <PropertyClaim onPropertyClaim={onPropertyClaim} onCancel={onCancel} />
    </Container>
  )
}

export default withAuthenticationRequired(CreateClaim, {
  onRedirecting: () => <Loading />,
})

const ActionButton: React.FC<React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>> = ({
  children,
  ...props
}) => {
  return (
    <NewSecondaryButton width={140} altcolor={BluebidBlue} {...props}>
      {children}
    </NewSecondaryButton>
  )
}

export const GetDataPopup: React.FC<{
  iconOnly: boolean
  onSelect: (detail: PropertyDetail, overwrite: boolean) => void
  address: string
  enabled: boolean
  checkOnly: boolean
}> = ({ iconOnly, onSelect, address, enabled, checkOnly }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const onAction = (detail: PropertyDetail, overwrite: boolean) => {
    toggle()
    onSelect(detail, overwrite)
  }

  return (
    <>
      {iconOnly ? (
        <IconActionButton disabled={!enabled} onClick={toggle}>
          <FontAwesomeIcon icon={faServer} />
        </IconActionButton>
      ) : (
        <ActionButton disabled={!enabled} onClick={toggle}>
          <FontAwesomeIcon icon={faServer} /> ATOM Data
        </ActionButton>
      )}
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>ATOM Data for: {address}</ModalHeader>
        <ModalBody>
          <ATOMDataBrowser address={address} onCancel={toggle} onSelect={onAction} checkOnly={checkOnly} />
        </ModalBody>
      </Modal>
    </>
  )
}
